import { h, Component } from 'preact';
import { trackNavClick } from '../tracking/google';
import { Config } from '../context';

export class Button extends Component {
  clickHandler({ onClick, label, location, ga }) {
    return evt => {
      trackNavClick({ label, location, ga });
      return onClick(evt, label, location);
    };
  }

  render({ onClick, children, label, location, ...props }) {
    return (
      <Config.Consumer>
        {config => (
          <button onClick={this.clickHandler({ onClick, label, location, ga: config.ga })} type="button" {...props}>{children}</button>
        )}
      </Config.Consumer>
    );
  }
}
