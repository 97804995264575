import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 1024,
  height: 1024,
  id: 'lindo-badge'
};

export const LindoBadge = () => (
  <symbol {...attributes}>
    <path d="M955.3 256C814 11.2 500.9-72.7 256 68.7 11.2 210-72.7 523.1 68.7 768 210 1012.8 523.1 1096.7 768 955.3 1012.8 814 1096.7 500.9 955.3 256z"/>
    <path fill="#222" id="lindo" d="M209.2 554.9l105.4 182.5-49.2 28.4L160 583.4l49.2-28.5zm70.5 13.6c15.3-8.8 20.5-28.3 11.7-43.6s-28.3-20.5-43.6-11.7-20.5 28.3-11.7 43.6c8.9 15.4 28.3 20.6 43.6 11.7zm-19 23.8L337 724.4l49.2-28.4-76.3-132.1-49.2 28.4zM425.2 493c-23.3 13.4-32.2 31.4-34.8 44.5l-8.7-15-49.2 28.4L408.7 683l49.2-28.4-47.1-81.5c1.4-7 3.8-16.1 15.1-22.6 11.8-6.8 22.2-5.9 30 7.8l42.2 73 49.2-28.4-54.2-93.8c-13.1-23-37.3-33.8-67.9-16.1zM602 328.1l105.4 182.5-49.3 28.4-8.1-14c-4 19.1-13.9 31-27.3 38.7-32 18.5-72.2 9.6-98.4-35.8-25.3-43.8-14.2-84.1 18.3-102.9 13.4-7.7 29.5-10.8 47.2-4.2l-37.1-64.3 49.3-28.4zm8.1 127.8c-8-3.1-19-.7-25.6 3.1-13.4 7.7-19.3 23.5-9.8 39.9s26.2 19.5 39.6 11.8c6.6-3.8 14-12.4 15.3-20.9l-19.5-33.9zM832 350.4c21.3 36.9 12.8 85.3-34.3 112.4-46.8 27-92.9 10.2-114.3-26.7-21.3-36.9-12.6-85 34.2-112 47.1-27.1 93.1-10.6 114.4 26.3zm-50.6 29.2c-8.4-14.5-23.3-20.8-38.6-12-15 8.7-17.1 24.8-8.7 39.3 8.5 14.8 23.5 21.1 38.5 12.4 15.3-8.8 17.3-24.9 8.8-39.7z"/>
  </symbol>
);

export const UseLindoBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
