const featureFlags = [
  /*
  // Example usage
  {
    name: 'newsletter-popup',
    variations: ['control', 'on'],
    isEligible: () => true,
  },*/
];

export const abTests = [];

export default [...featureFlags, ...abTests];
