const PASSTHROUGH_KEY = 'user_auth_passthrough';
const TEASER_PASSTHROUGH_TYPES = ['trophy_earned'];

/**
 * =========================================
 * TEASER NOTIFICATIONS FOR LOGGED-OUT USERS
 * =========================================
 *
 * Notifications are generally for logged-in users, but we used them to show
 * unauthenticated users what they're missing.
 *
 * Teaser notifications are triggered by reading from the user_auth_passthrough
 * entry in localStorage. If there is an entry that we have decided triggers a
 * teaser notification, we set the state of the NotifUserMenu to contain a
 * notification based on that trigger. The logic for what causes teaser noti-
 * fications lives in this file. Currently, only earning the homepage view
 * streak trophy while logged out causes a teaser notification.
 *
 * getTeaserNotifications is the main function here. It collects up noti-
 * fications from fetching functions per type.
 *
 * These teaser notifications masquerade as real notifications once they're in
 * the notifications components. To see where the switch happens, look at the
 * updateNotifications method of NotifUserMenu.
 *
 */
export function getLocalStorageItem(key) {
  const value = window.localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
}

export function getTeaserNotifications() {
  return [];
}

export function removeTeaserNotifications() {
  const userAuthPassthrough = getLocalStorageItem(PASSTHROUGH_KEY);
  if (!userAuthPassthrough) {
    return;
  }

  const filtered = userAuthPassthrough.filter(
    (entry) => !TEASER_PASSTHROUGH_TYPES.includes(entry.type)
  );
  window.localStorage.setItem(PASSTHROUGH_KEY, JSON.stringify(filtered));
}
