import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 1024,
  height: 1024,
  id: 'cute-badge'
};

export const CuteBadge = () => (
  <symbol {...attributes}>
    <path d="M955.3 256C814 11.2 500.9-72.7 256 68.7 11.2 210-72.7 523.1 68.7 768 210 1012.8 523.1 1096.7 768 955.3 1012.8 814 1096.7 500.9 955.3 256z"/>
    <path fill="#222" d="M331.4 648.8l56.3 13.5c-1.2 17.4-13.3 45.7-47.8 65.6-48 27.7-103 17.7-130.9-30.6-27.7-48-8.8-100.7 39.2-128.3 34.5-19.9 64.8-16.5 80.7-8.6l-16.6 55.2c-8.9-4.5-20.9-5.1-32.8 1.8-16 9.2-24 27.2-12.8 46.7 11.2 19.4 31 21.8 47 12.6 11.8-7 17-18.3 17.7-27.9zm96.5-178.6l53.9 93.4c-1.6 8-4.4 18.4-16.9 25.7-13.8 8-25.4 6.7-34.2-8.6l-48.5-84-56.4 32.6 62.4 108.1c15 26 42.2 38.3 77.3 18.1 26.6-15.4 36.9-35.9 40.1-50.7l9.8 16.9 56.4-32.6-87.4-151.4-56.5 32.5zm225.6 10.2c-7.5 4.3-16.2 1-20.7-6.9l-24.4-42.3 30.7-17.7-28.2-48.9-30.7 17.7-23.9-41.4-56.4 32.6 23.9 41.4-25.1 14.5 28.2 48.9 25.1-14.5 33.8 58.6c18.3 31.7 46 37 79.5 17.6 19.1-11 29.6-23.3 32.4-32.1L663.1 471c-.5 2-4.6 6.5-9.6 9.4zm149.1-91.2l43.3 21.4c-7.6 21.5-31.1 43.1-53.7 56.1-47.3 27.3-101.5 18.9-130.3-30.9-25.3-43.9-12.2-98.7 36.7-126.9 44.5-25.7 97.1-13.5 127.9 39.8l6.7 11.6-102.8 59.4c8.7 7.9 25.1 11 43.6.3 8.4-4.9 24.9-19.8 28.6-30.8zm-93.5-4.9l49.8-28.8c-5.4-5.7-17.4-14.2-35.6-3.7-17.2 10-16.3 24.5-14.2 32.5z"/>
  </symbol>
);

export const UseCuteBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
