import { h } from 'preact';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './toast.scss';

const Toast = ({ message, icon, showToast, className }) => {
  if (!showToast || !message) {
    return null;
  }

  return (
    <div className={classnames(styles.toast, className)}>
      {icon}
      {message}
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.element,
  showToast: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Toast;
