import { h, Component } from 'preact';
import styles from './styles.scss';
import { Config, I18n } from '../context';
import { trackNavClick } from '../tracking/google';

export class SkipNav extends Component {
  clickHandler(ga) {
    return () => {
      trackNavClick({ location: 'skipNav', ga });
    };
  }

  render() {
    return (
      <Config.Consumer>
        {config => (
          <I18n.Consumer>
            {i18n => <a href="#buzz-content" className={styles.skipNav} onClick={this.clickHandler(config.ga)}>{i18n.skip_to_content}</a>}
          </I18n.Consumer>
        )}
      </Config.Consumer>
    );
  }
}
