import { h } from 'preact';
import { SvgUse } from '..';

const attributes = {
  width: 386,
  height: 45,
  id: 'huffpost-logo'
};

export const HuffPostLogo = () => (
  <symbol {...attributes}>
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.782 44.066H0V0.904007H23.394L15.782 44.066Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M57.299 0.918304L54.544 16.3773H42.345L45.1 0.918304H31.051L23.504 44.0823H37.557L40.388 27.9343H52.586L49.754 44.0823H63.806L71.354 0.918304H57.299Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M79.3404 0.918701L75.0424 24.9267C74.6914 26.8217 74.5214 28.5917 74.5214 30.3447C74.5214 43.0967 85.6704 45.0007 92.3154 45.0007C106.328 45.0007 112.358 40.3407 114.561 27.8067L119.263 0.918701H105.209L101.216 23.4707C99.9404 30.5287 99.0294 34.2097 93.7694 34.2097C90.3454 34.2097 88.6804 32.3947 88.6804 28.6607C88.6804 27.2397 88.8864 25.4787 89.3094 23.2767L93.3874 0.918701H79.3404Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M127.329 0.918701L119.781 44.0817H133.833L136.053 31.3777H147.88L149.842 20.5107H138.028L139.334 12.4747H155.875L157.911 0.918701H127.329Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M164.217 0.918701L156.669 44.0817H170.722L172.941 31.3777H184.775L186.737 20.5107H174.916L176.222 12.4747H192.764L194.799 0.918701H164.217Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M215.532 22.3472H211.474L213.359 12.1682H217.139C220.223 12.1682 221.922 13.7312 221.922 16.5692C221.922 20.1332 219.474 22.3472 215.532 22.3472ZM193.635 44.0822H207.691L209.681 32.9852H216.297C228.561 32.9852 235.315 26.3392 235.315 14.2732C235.315 5.7862 229.415 0.918198 219.129 0.918198H201.183L193.635 44.0822Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M258.543 33.827C254.925 33.827 252.765 31.209 252.765 26.824C252.765 25.149 252.908 23.72 253.243 22.045C254.325 16.402 256.116 11.174 261.834 11.174C265.452 11.174 267.612 13.792 267.612 18.176C267.612 19.851 267.469 21.28 267.135 22.952C266.051 28.601 264.26 33.827 258.543 33.827ZM262.676 0C247.365 0 238.224 9.913 238.224 26.518C238.224 38.091 245.505 45 257.701 45C273.012 45 282.152 35.087 282.152 18.483C282.152 6.91 274.871 0 262.676 0Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M307.754 16.8716C303.323 15.4766 301.491 14.8996 301.491 12.8196C301.491 11.4246 302.423 9.79659 305.05 9.79659C307.031 9.79659 308.664 10.9196 309.34 12.5666L321.767 9.2246C320.332 3.1066 315.067 0.000595093 306.045 0.000595093C289.038 0.000595093 287.716 11.3936 287.716 14.8856C287.716 21.9656 291.48 26.2926 299.563 28.5036C301.718 29.0746 304.17 29.7236 304.17 32.1046C304.17 33.9976 302.754 35.1276 300.382 35.1276C298.185 35.1276 295.854 33.8206 295.011 31.6126L282.745 34.9396C284.098 41.3516 290.141 45.0006 299.616 45.0006C306.833 45.0006 318.94 43.0566 318.94 30.0386C318.94 23.4886 315.281 19.1816 307.754 16.8716Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M331.036 44.0819H345.091L350.677 12.4749H361.289L363.325 0.9189H327.334L325.298 12.4749H336.546L331.036 44.0819Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M369.393 0.904007H385.175V44.066H361.781L369.393 0.904007Z" fill="white"/>
    </g>
  </symbol>
);

export const UseHuffPostLogo = ({...props}) => (
  <SvgUse {...attributes} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
