import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 512,
  height: 512,
  id: 'oooh-badge'
};

export const OoohBadge = () => (
  <symbol {...attributes}>
    <path d="M501 257c0 134.758-109.24 244-244 244-134.758 0-244-109.242-244-244C13 122.24 122.24 13 257 13s244 109.24 244 244" />
    <path fill="#222" d="M166 319.625c-12.355-21.393-39-30.947-66.26-15.207-27.1 15.646-32.15 43.496-19.798 64.895 12.354 21.396 39.09 31.104 66.19 15.457 27.26-15.74 32.22-43.752 19.868-65.145zm-34.417 39.943c-8.716 5.03-17.383 1.37-22.32-7.188-4.85-8.395-3.69-17.734 5.028-22.768 8.876-5.123 17.54-1.46 22.39 6.94 4.94 8.56 3.777 17.896-5.097 23.016zm125.858-92.736c-12.353-21.398-38.995-30.95-66.254-15.21-27.1 15.647-32.15 43.5-19.8 64.892 12.35 21.396 39.09 31.104 66.19 15.46 27.26-15.738 32.22-43.75 19.865-65.142zm-34.414 39.94c-8.716 5.03-17.38 1.37-22.32-7.188-4.85-8.4-3.69-17.737 5.026-22.767 8.875-5.123 17.54-1.463 22.39 6.937 4.94 8.558 3.778 17.892-5.096 23.015zm125.865-92.738c-12.352-21.394-38.995-30.947-66.258-15.204-27.1 15.646-32.15 43.497-19.8 64.895 12.352 21.393 39.09 31.1 66.188 15.455 27.258-15.742 32.22-43.753 19.87-65.146zm-34.415 39.942c-8.717 5.036-17.386 1.373-22.32-7.183-4.848-8.398-3.69-17.737 5.03-22.768 8.88-5.126 17.538-1.467 22.394 6.938 4.928 8.557 3.768 17.89-5.105 23.013zm148.746-55.203l-31.565-54.677c-7.688-13.313-21.496-19.287-39.24-9.04-13.475 7.777-18.668 18.17-20.184 25.804l-21.87-37.87-28.52 16.47 61.03 105.705 28.52-16.47-27.266-47.224c.82-4.066 2.228-9.314 8.726-13.067 6.815-3.93 12.65-3.71 17.224 4.216l24.62 42.63 28.525-16.478z" />
  </symbol>
);

export const UseOoohBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
