import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 200,
  height: 200,
  id: 'kawaii-badge'
};

export const KawaiiBadge = () => (
  <symbol {...attributes}>
    <path d="M186.588 50.008c27.61 47.822 11.225 108.971-36.596 136.58-47.822 27.61-108.97 11.225-136.58-36.596-27.61-47.822-11.225-108.97 36.596-136.58 47.822-27.61 108.971-11.225 136.58 36.596l-50.62 15.96"/>
    <path fill="#222" d="M67.321 140.898l-14.835-7.254 1.758-14.249-9.789 5.651-.527 10.65-9.272-16.06-8.12 4.688 17.373 30.089 8.12-4.688-2.839-4.917.355-3.032 7.807 4.876 9.969-5.754zm22.42-12.945l-7.606-13.173c-4.193-7.262-10.564-6.109-15.978-2.984-3.294 1.901-6.806 5.192-8.385 9.23l5.643 3.42c.96-2.298 2.791-4.438 4.821-5.611 2.662-1.535 4.737-1.17 5.623.363l1.303 2.256c-2.032-.812-5.125-.229-7.967 1.412-3.248 1.875-6.603 5.858-3.477 11.271 2.865 4.962 8.382 4.725 11.629 2.85 2.705-1.563 4.743-4.183 5.075-6.42l1.197 2.075 8.12-4.687.002-.002zm-14.058 1.801c-1.353.782-3.077 1.235-3.858-.119-.782-1.353.448-2.662 1.8-3.445 1.263-.728 2.897-1.13 3.88-.676l.703 1.219c-.097 1.079-1.26 2.292-2.525 3.021zm46.853-20.735l-6.264-25.435-8.392 4.844 4.041 13.668-10.717-9.812-7.263 4.192 3.093 14.213-9.815-10.332-8.345 4.819 18.895 18.142 8.841-5.106-3.512-14.209 10.595 10.122 8.844-5.106h-.001zm28.735-16.589l-7.603-13.172c-4.195-7.262-10.566-6.11-15.979-2.985-3.293 1.901-6.806 5.193-8.384 9.23l5.642 3.419c.961-2.297 2.792-4.438 4.821-5.611 2.661-1.535 4.738-1.17 5.623.363l1.303 2.256c-2.032-.812-5.125-.229-7.967 1.412-3.248 1.875-6.604 5.858-3.479 11.271 2.866 4.963 8.38 4.725 11.631 2.85 2.706-1.563 4.742-4.183 5.076-6.42l1.197 2.075 8.12-4.688h-.001zm-14.056 1.8c-1.353.782-3.076 1.235-3.856-.119-.782-1.353.445-2.663 1.801-3.445 1.261-.729 2.895-1.13 3.879-.677l.703 1.219c-.101 1.08-1.263 2.295-2.527 3.022zm9.139-30.116c2.525-1.459 3.383-4.661 1.924-7.187-1.458-2.527-4.66-3.385-7.186-1.926s-3.384 4.661-1.926 7.187 4.66 3.383 7.188 1.926zm17.55 21.023l-12.58-21.788-8.12 4.688 12.579 21.788 8.121-4.688zm-4.919-28.317c2.524-1.459 3.383-4.661 1.924-7.187-1.458-2.528-4.66-3.385-7.186-1.926-2.526 1.458-3.384 4.661-1.926 7.187 1.458 2.525 4.66 3.383 7.186 1.925l.002.001zm17.551 21.023l-12.58-21.788-8.12 4.688 12.579 21.788 8.119-4.688h.002z"/>
  </symbol>
);

export const UseKawaiiBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
