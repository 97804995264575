import { h, Component } from 'preact';
import { trackNavClick } from '../tracking/google';
import { Config, Tracking } from '../context';
import { withTheme } from '../withTheme';

export class Link extends Component {
  clickHandler({
    onClick,
    label,
    location,
    isInfoPage,
    ga,
    url,
    trackingData,
    trackCETNavClick,
  }) {
    return evt => {
      trackNavClick({ label, location, ga });
      trackCETNavClick({ location, url, isInfoPage, label, trackingData });
      return onClick(evt);
    };
  }

  render({ href, children, label, location, isInfoPage, onClick = () => {}, trackingData, ...props }) {
    return (
      <Config.Consumer>
        {config => {
          const url = href.match(/^https?:/) ? href : `${config.bf_url}${href}`;
          return (
            <Tracking.Consumer>
              {cet => {
                return (<a
                  href={url}
                  onClick={this.clickHandler({
                    onClick,
                    label,
                    location,
                    isInfoPage,
                    ga: config.ga,
                    url,
                    trackingData,
                    trackCETNavClick: cet.trackNavClick
                  })}
                  {...props}
                >
                  {children}
                </a>);
              }}
            </Tracking.Consumer>
          );
        }}
      </Config.Consumer>
    );
  }
}

export const ThemedLink = withTheme(({ className, theme, ...props }) => (
  <Link className={`${className} ${theme.link}`} {...props} />
));
