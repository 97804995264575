import { h } from 'preact';
import { SvgUse } from '..';

const attributes = {
  id: 'arcade',
  width: 13,
  height: 15,
};

export const Arcade = (props) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props}>
    <path d="M0.115133 8.18548L3.35639 9.99933C3.40739 10.0278 3.4449 10.0717 3.4614 10.1232L4.76398 14.0924C4.83299 14.3025 5.16701 14.3025 5.23602 14.0924L6.5386 10.1232C6.5556 10.0717 6.59311 10.0278 6.64361 9.99933L9.88487 8.18548C10.0384 8.0996 10.0384 7.9004 9.88487 7.81452L6.64361 6.00067C6.59261 5.97221 6.5551 5.92827 6.5386 5.87685L5.23602 1.90764C5.16701 1.69745 4.83299 1.69745 4.76398 1.90764L3.4614 5.87685C3.4444 5.92827 3.40689 5.97221 3.35639 6.00067L0.115133 7.81452C-0.0383778 7.9004 -0.0383778 8.0996 0.115133 8.18548Z" fill="#481F6B"/>
    <path d="M9.04605 2.57419L10.3426 3.29973C10.363 3.31112 10.378 3.32869 10.3846 3.34926L10.9056 4.93694C10.9332 5.02102 11.0668 5.02102 11.0944 4.93694L11.6154 3.34926C11.6222 3.32869 11.6372 3.31112 11.6574 3.29973L12.9539 2.57419C13.0154 2.53984 13.0154 2.46016 12.9539 2.42581L11.6574 1.70027C11.637 1.68888 11.622 1.67131 11.6154 1.65074L11.0944 0.0630579C11.0668 -0.0210193 10.9332 -0.0210193 10.9056 0.0630579L10.3846 1.65074C10.3778 1.67131 10.3628 1.68888 10.3426 1.70027L9.04605 2.42581C8.98465 2.46016 8.98465 2.53984 9.04605 2.57419Z" fill="#481F6B"/>
  </SvgUse>
);
