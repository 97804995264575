export default {
  bfo: {
    displayName: 'BuzzFeed'
  },
  bfreviews: {
    displayName: 'BuzzFeed Reviews'
  },
  tasty: {
    displayName: 'BuzzFeed Tasty'
  },
  goodful: {
    displayName: 'BuzzFeed Goodful'
  },
  asis: {
    displayName: 'BuzzFeed As Is'
  },
  perolike: {
    displayName: 'BuzzFeed Pero Like'
  },
  cocoabutter: {
    displayName: 'BuzzFeed Cocoa Butter'
  },
  tastyhome: {
    displayName: 'BuzzFeed Tasty Home'
  },
  huffpost: {
    displayName: 'HuffPost'
  }
};
