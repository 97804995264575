import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  id: 'search-icon',
  width: 38,
  height: 38
};

export const SearchIcon = () => (
  <symbol {...attributes}>
    <path d="M24.5 1C17.6 1 12 6.6 12 13.5c0 2.7.9 5.2 2.4 7.3L.6 34.6c-.8.8-.8 2 0 2.8.8.8 2 .8 2.8 0l13.8-13.8c2.1 1.5 4.6 2.4 7.3 2.4C31.4 26 37 20.4 37 13.5S31.4 1 24.5 1zm0 21c-4.7 0-8.5-3.8-8.5-8.5S19.8 5 24.5 5 33 8.8 33 13.5 29.2 22 24.5 22z"/>
  </symbol>
);

export const UseSearchIcon = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);

export const SearchIconNew = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1051 10.125C11.1245 9.13241 11.757 7.74898 11.757 6.21875C11.757 3.19844 9.29299 0.75 6.2535 0.75C3.214 0.75 0.75 3.19844 0.75 6.21875C0.75 9.23906 3.214 11.6875 6.2535 11.6875C7.75305 11.6875 9.11252 11.0916 10.1051 10.125ZM10.1051 10.125L13.25 13.25" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
);
