import { h } from 'preact';
import styles from './styles.scss';

const RETURN_KEY = 13;

const getNotificationProps = (teaserNotification = {}) => {
  const { type } = teaserNotification;

  if (type) {
    switch (type) {
    case 'teaser_trophy_added':
    default:
      return {
        text: 'Trophy earned!',
        label: 'Trophy earned. Click to dismiss',
      };
    }
  }

  return {
    text: 'Trophy earned!',
    label: 'Trophy earned. Click to dismiss',
  };
};

export default function NotificationTooltip({
  teaserNotifications,
  tooltipDismissed,
  onNotifTooltipClicked,
  setShowNotifToolotip,
  isNewOrCommunityUser,
  isLoggedInUser,
  children,
}) {
  /* Handle the event here so that we can stop the click event bubbling up to
   * the avatar, which would also toggle the visibility of the user menu, which
   * would mean the menu ends up closed again. */
  const handleClick = (e) => {
    e.stopPropagation();
    onNotifTooltipClicked();
  };

  const handleKeypress = ({ keyCode }) => {
    if (keyCode === RETURN_KEY) {
      onNotifTooltipClicked();
    }
  };

  const notificationProps = getNotificationProps(teaserNotifications[0]);
  const hasTeaserNotifications = teaserNotifications && teaserNotifications.length > 0;
  //logged in users should not see quiz streak tooltip and teaser
  const isTooltipSuppressed = isLoggedInUser;
  const isShowingNotifTooltip = isNewOrCommunityUser &&
        hasTeaserNotifications &&
        tooltipDismissed === false &&
        !isTooltipSuppressed;
  setShowNotifToolotip(isShowingNotifTooltip);
  return (
    <div className={styles.notifTooltipWrapper}>
      {children}
      {isShowingNotifTooltip && (
        <button
          aria-label={notificationProps.label}
          className={styles.notifTooltip}
          onClick={handleClick}
          onKeyPress={handleKeypress}
        >
          <span className={styles.notifTooltipText}>
            {notificationProps.text}
          </span>
        </button>
      )}
    </div>
  );
}
