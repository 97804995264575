import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 500,
  height: 500,
  id: 'doubutsu-badge',
  fill: 'none'
};

export const DoubutsuBadge = () => (
  <symbol {...attributes}>
    <g clip-path="url(#clip0_17_7)">
      <path d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z" fill="#FFF000"/>
      <path d="M156.3 381.7L147.3 368.9L151.9 366.3C160 361.6 167.8 356.6 175.6 351.7L171.4 344.5L145.6 359.4L138.9 347.7L164.7 332.8L161.6 327.4L136.7 341.8L110.4 296.3L135.3 281.9L132.5 277L104.1 293.4L97.1 281.2L125.5 264.8L122.7 259.9C115.9 264.2 109 268.3 102.1 272.3L97.4 275L89 264.4C110.8 251.8 129.8 240.3 149 224L160.3 233.2C153 239.9 144.9 245.6 136.7 251L139.9 256.5L168.2 240.2L175.2 252.4L146.9 268.7L149.7 273.6L175.1 258.9L201.4 304.4L176 319.1L179.1 324.5L205.8 309.1L212.5 320.8L185.8 336.2L189.5 342.6C197.1 337.3 205 332.4 212.5 327C213.3 309.3 210.1 296.4 203.2 280.3C199.6 271.6 195.5 263.1 190.8 254.8L181.4 260.2L172.2 244.3L181.6 238.9L167.3 214.1L183 205L197.3 229.8L220.8 216.3C230.3 232.8 239.4 249.8 248.8 266.6C252.5 273.4 256.1 280.2 259.5 287.2C263.1 294.6 267.3 303.1 268 311.3C268.6 318.7 264.6 322.4 258.7 325.8L245.9 333.2L233.9 318.5L244 312.7C249.1 309.7 248.6 307.6 246.7 302.6C242.9 292.4 221.3 252.8 214.7 241.2L206.6 245.9C214.9 260.2 221.8 275.6 226.7 291.3C231.9 307.9 233.5 325.5 230.3 342.6L218.1 340.9C198 355.1 177.1 368.4 156.3 381.7ZM129.9 298.7L134.4 306.4L145.8 299.8L141.3 292.1L129.9 298.7ZM139.5 315.3L144.1 323.3L155.5 316.7L150.9 308.7L139.5 315.3ZM155.6 283.8L160.1 291.5L172 284.6L167.5 276.9L155.6 283.8ZM165.2 300.5L169.8 308.5L181.7 301.6L177.1 293.6L165.2 300.5Z" fill="black"/>
      <path d="M325.3 165.5C334.5 196.6 333.5 225.6 322.5 256.1L306.2 248.5C316 226.1 317.7 197.3 310.8 173.8L304 177.7C305.3 189 306.3 202.8 303.5 213.8L285.3 208C285.9 205.4 286.3 202.9 286.5 200.1L279 204.4L292.4 227.6C295.3 224.4 297.9 221.1 300.7 217.7L310.7 232.6C307.8 236.9 305.4 240 302 244.1L326.7 286.8L311.2 295.7L289.5 258.1C283.4 264.4 277.2 270.6 270.9 276.7L258.3 261.6C265.7 255.1 272.9 248.4 279.8 241.3L263.6 213.2L256 217.6C258.8 224.9 262.6 235.3 263.2 243L245.3 239C241.3 220.7 234.8 208.2 226.1 191.9L239.9 184.6C240.6 185.6 241.2 186.6 241.8 187.7C244.2 191.9 246.5 196.4 248.6 200.7L254.4 197.4L240 172.4L255.5 163.5L270.1 188.8L280.6 182.7L287.2 194.1C287.4 179.3 283.3 163.8 278.7 149.7L296.3 141.7C298.2 147.8 299.9 153.8 301.4 159.9L347.5 133.3C358.9 153.1 391.5 209.8 397.4 227.9C400.5 237.1 399.8 245.1 390.7 250.3L369.7 262.4L357.6 247.7L374.2 238.1C378.4 235.7 379.7 234.6 378 229.6C373.7 217.2 359.8 191.4 353 179C360 213.4 359.7 243.3 345.8 276.1L328.6 268.4C344.1 234.1 344.7 196.4 334.1 160.5L325.3 165.5Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_17_7">
        <rect width="500" height="500" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
);

export const UseDoubutsuBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
