import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 1024,
  height: 1024,
  id: 'hahaha-badge'
};

export const HahahaBadge = () => (
  <symbol {...attributes}>
    <path d="M955.3 256C814 11.2 500.9-72.7 256 68.7 11.2 210-72.7 523.1 68.7 768 210 1012.8 523.1 1096.7 768 955.3 1012.8 814 1096.7 500.9 955.3 256z"/>
    <path fill="#222" id="hahaha" d="M359.1 447.9l-47.5 27.4-101.5-175.9 47.5-27.4 36.4 63c2.5-12.7 11.2-30 33.6-42.9 29.5-17.1 52.5-7.1 65.3 15l52.5 91-47.5 27.4-41-70.9c-7.6-13.2-17.3-13.6-28.7-7-10.8 6.2-13.2 15-14.5 21.7l45.4 78.6zM435 294.8l-33-20c9.2-23.6 29.8-42.9 49-54 31.6-18.3 68.9-25 93.4 17.5l44.5 77-47.5 27.4-7-12.1c-1.9 13.1-13.9 28.4-29.7 37.5-19 11-51.2 12.4-68-16.7-18.3-31.6 1.3-54.9 20.3-65.9 16.6-9.6 34.7-13 46.6-8.3L496 264c-5.2-9-17.3-11.1-32.9-2.1-11.8 6.9-22.5 19.4-28.1 32.9zm59.7 10.2c-7.9 4.6-15.1 12.2-10.5 20.1 4.6 7.9 14.6 5.3 22.6.7 7.4-4.3 14.2-11.4 14.8-17.7l-4.1-7.1c-5.9-2.6-15.4-.3-22.8 4zm-64.3 266.4c1.4-6.8 3.7-15.5 14.5-21.7 11.3-6.5 21.1-6.2 28.7 7l41 70.9 47.5-27.4-52.5-91c-12.8-22.2-35.8-32.1-65.3-15-22.4 12.9-31.1 30.2-33.6 42.9l-36.4-63-47.5 27.4 101.6 175.9 47.5-27.4-45.5-78.6zm137.3-148.5c31.6-18.3 68.9-25 93.4 17.5l44.5 77-47.5 27.4-7-12.1c-1.9 13.1-13.8 28.4-29.7 37.5-19 11-51.2 12.4-68-16.7-18.3-31.6 1.3-54.9 20.3-65.9 16.6-9.6 34.7-13 46.6-8.3l-7.6-13.2c-5.2-9-17.3-11.1-32.9-2.1-11.9 6.9-22.6 19.4-28.2 32.8l-33-20c9.3-23.6 29.8-42.8 49.1-53.9zm43.6 84.1c-7.9 4.6-15.1 12.2-10.5 20.1 4.6 7.9 14.6 5.3 22.6.7 7.4-4.3 14.2-11.4 14.8-17.7l-4.1-7.1c-5.8-2.6-15.4-.2-22.8 4zm-50.4 189.3c-22.4 12.9-31.1 30.2-33.6 42.9l-36.4-63-47.5 27.4L545 879.5l47.5-27.4-45.4-78.6c1.4-6.8 3.7-15.5 14.5-21.7 11.3-6.5 21.1-6.2 28.7 7l41 70.9 47.5-27.4-52.5-91c-12.9-22.2-35.9-32.1-65.4-15zm216.9-53.9l44.5 77-47.5 27.4-7-12.1c-1.9 13.1-13.8 28.4-29.7 37.5-19 11-51.2 12.4-68-16.7-18.3-31.6 1.3-54.9 20.3-65.9 16.6-9.6 34.7-13 46.6-8.3l-7.6-13.2c-5.2-9-17.3-11.1-32.9-2.1-11.9 6.9-22.6 19.4-28.2 32.8l-33-20c9.2-23.6 29.8-42.9 49-54 31.7-18.1 68.9-24.8 93.5 17.6zm-23 69.9l-4.1-7.1c-5.8-2.7-15.3-.3-22.7 4-7.9 4.6-15.1 12.2-10.5 20.1 4.6 7.9 14.6 5.3 22.6.7 7.3-4.3 14.1-11.4 14.7-17.7z"/>
  </symbol>
);

export const UseHahahaBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
