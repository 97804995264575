import { h, Component } from 'preact';

class GoogleOneTap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleOneTapEnabled: false,
      loginUri: '',
      xsrf: '',
      redirectUrl: '',
    };
  }

  componentDidMount() {
    let googleOneTapEnabled = false;
    if (!window.location.pathname.startsWith('/bpage-preview') && this.props.edition !== 'jp') {
      googleOneTapEnabled = true;
      this.setState({ googleOneTapEnabled: googleOneTapEnabled });
    }

    if (!googleOneTapEnabled) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    let xsrfCookie = '';
    document.cookie.split('; ').forEach((item) => {
      if (item.startsWith('_xsrf')) {
        xsrfCookie = item.split('=')[1];
        this.setState({ xsrf: xsrfCookie });
      }
    });
    if (xsrfCookie === '') {
      xsrfCookie = Math.random().toString(36).substring(2);
      document.cookie = '_xsrf=' + xsrfCookie + '; path=/';
      this.setState({ xsrf: xsrfCookie });
    }

    this.setState({
      loginUri: document.location.origin + '/auth/signin/google-one-tap',
      redirectUrl: document.location.href,
    });
  }

  render() {
    return (
      this.state.xsrf &&
      this.state.googleOneTapEnabled && (
        <div
          id="g_id_onload"
          data-client_id="761451303051-cptuhojsap0trrfcapamqqj860t3cshu.apps.googleusercontent.com"
          data-context="signin"
          data-login_uri={this.state.loginUri}
          data-skip_prompt_cookie="bf2-b_info"
          data-_xsrf={this.state.xsrf}
          data-itp_support="true"
          data-use_fedcm_for_prompt="true"
          data-redirect_url={this.state.redirectUrl}
        />
      )
    );
  }
}

export default GoogleOneTap;
