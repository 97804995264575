/* eslint-disable quotes */
const mock = {
  newsletters: [
    {
      name: 'buzzfeed_email_daily',
      slug: 'buzzfeed-daily',
      title: 'BuzzFeed Daily',
      description: 'Keep up with the latest daily buzz with the BuzzFeed Daily newsletter!',
      frequency: '5 updates a week',
      image: 'https://img.buzzfeed.com/store-an-image-stage-us-east-1/DnIwt1zIv.gif',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_tasty',
      slug: 'tasty',
      title: 'Tasty',
      description: 'Recipes, food quizzes, & cooking hacks.',
      frequency: '4 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/tasty-banner.4a67be8439b077d014d554646015d1e2.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_books',
      slug: 'book',
      title: 'Books',
      description: 'The newsletter for all things books! Get recommendations for your TBR, bookish quizzes, and info on new releases.',
      frequency: '2 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/books-banner.a3436be4d2e4579f699a0e57b0afdb37.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_quizzes',
      slug: 'quizzes',
      title: 'Quizzes',
      description: 'The quizzes you know and love delivered straight to your inbox!',
      frequency: '3 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/quizzes-banner.2a6a3220521d83e523e772655fd7405b.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_suspicious',
      slug: 'suspicious-circumstances',
      title: 'Suspicious Circumstances',
      description: 'A true crime inside guide to the biggest unsolved mysteries, white collar scandals, and grim cases of today.',
      frequency: 'weekly on Tuesdays',
      image: 'https://www.buzzfeed.com/static-assets/img/suspiciouscircumstances-banner.86797ea7c0e0f4f968447f3f5d1d422e.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_nifty',
      slug: 'nifty',
      title: 'Nifty',
      description: 'DIY projects, organization tips, & home decor recommendations.',
      frequency: '3 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/nifty-banner.05e8cbe401f3a1c4d1d101759a32a831.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_shopping',
      slug: 'shopping',
      title: 'Shopping',
      description: 'Find great products - plus cool stuff you didn’t even know you needed.',
      frequency: '3 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/shopping-banner.ef0739cdec4787dd652d74174a2ebdcf.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_incoming',
      slug: 'incoming',
      title: 'Incoming',
      description: 'Your weekday morning guide to breaking news, cultural analysis, and everything in between',
      frequency: '5 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/incomingnews-banner.349462814c804424f475ac4cdf2cc595.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_shop-buzzfeed',
      slug: 'shop-buzzfeed-merch',
      title: 'Shop BuzzFeed Merch',
      description: 'Love BuzzFeed as much as we do? Then sign up for our Shop BuzzFeed merch newsletter to stay up to date on all official show merch and promotions!',
      frequency: '1 update a week',
      image: 'https://www.buzzfeed.com/static-assets/img/merch-banner.d286b851e47560b3f0c62a4977dbdcb7.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_royal-tea',
      slug: 'royal-tea',
      title: 'The Royal Tea',
      description: 'Royal family news and analysis, served piping hot.',
      frequency: '1 update a month',
      image: 'https://www.buzzfeed.com/static-assets/img/royaltea-banner.78d8ec132d0e62b613cfc24425c75f41.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_asis',
      slug: 'asis',
      title: 'As/Is',
      description: 'Style tips, makeup advice, and must-have products.',
      frequency: '3 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/asis-banner.0da0dcb905112ae75646e8e3e6e03d73.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-better-skin',
      slug: 'course-better-skin',
      title: 'Better Skin Challenge',
      description: 'Tips from a dermatologist for getting clearer, healthier skin.',
      frequency: '7 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/koyRs2-N5.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-strong-core',
      slug: 'course-strong-core',
      title: 'Strong Core Challenge',
      description: 'An exercise plan to get stronger abs!',
      frequency: '21 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/fwyZaVEP8.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-best-semester',
      slug: 'course-best-semester',
      title: 'Best Semester Ever Course',
      description: 'Make college a bit easier this semester with these helpful tips.',
      frequency: '10 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/TjtlP6KUI.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_cleanse-the-timeline',
      slug: 'cleanse-the-timeline',
      title: 'Cleanse The Timeline',
      description: 'Our bimonthly newsletter covers every pivotal, unforgettable, zany moment in the cultural landscape.',
      frequency: 'twice a month',
      image: 'https://www.buzzfeed.com/static-assets/img/cleansetimeline-banner.2a3d493e8fe55145f1e0b5ebc79d83f5.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_course-dogs',
      slug: 'course-dogs',
      title: 'From Pup To Parenthood',
      description: 'A 30-day guide on becoming the best dog parent.',
      frequency: '30 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/ZHoqQUgYLL.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-chocolate-chip',
      slug: 'course-chocolate-chip',
      title: 'BuzzFeeds Best Chocolate Chip Cookie Guide',
      description: 'How to test different variables to bake the worlds tastiest chocolate chip cookie.',
      frequency: '9 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/tJOSUqcLC.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-tasty-meal-plan',
      slug: 'course-tasty-meal-plan',
      title: 'Tasty’s Make-Ahead Meal Plan',
      description: 'A 7-day plan of easy meals for your busy week.',
      frequency: '7 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/wiZ8vm9fR.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-natural-hair',
      slug: 'course-natural-hair',
      title: 'Natural Hair Challenge',
      description: 'Get healthier natural hair in less than a week.',
      frequency: '5 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Qd2wwPBM2.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_tvandmovies',
      slug: 'tvandmovies',
      title: 'TV & Movies',
      description: 'Get all the best moments in pop culture & entertainment delivered to your inbox.',
      frequency: '3 updates a week',
      image: 'https://www.buzzfeed.com/static-assets/img/tvandmovies-banner.8facfb8dab88289c675ce77e768c1582.jpg',
      brand: 'buzzfeed',
      category: 'trending',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_community_stats',
      slug: 'community-stats',
      title: 'Community Weekly Stats',
      description: 'Get a weekly rundown of your top-performing posts, where you rank leaderboard-wise, and more!',
      frequency: 'monthly',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/9v5YhcFtZ.png',
      brand: 'buzzfeed',
      category: 'icymi',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-spring-cleaning',
      slug: 'course-spring-cleaning',
      title: 'Spring Cleaning Challenge',
      description: 'Give your home a restart with this cleaning challenge.',
      frequency: '7 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/d3L-0pEun.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_jpg',
      slug: 'jpg',
      title: 'JPG',
      description: 'Your lens to the internets most powerful photographs.',
      frequency: '1 update a week',
      image: 'https://www.buzzfeed.com/static-assets/img/jpgnewsletter-banner.3dd29be166bb014f01e815662bc77323.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_course-less-waste',
      slug: 'course-less-waste',
      title: 'Goodful’s 7-Day Guide To Creating Less Waste',
      description: 'Little things you can do to help this big beautiful planet we call home.',
      frequency: '7 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/J9OiCkw96.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-detox-thoughts',
      slug: 'course-detox-thoughts',
      title: 'Goodful’s Detox Your Thoughts',
      description: 'A month-long guide to ditching the mental traps that are making you miserable.',
      frequency: '28 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/yU9VtK5Ev.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_community',
      slug: 'community',
      title: 'Community Contributor Updates',
      description: 'Get updated every time there’s a chance to earn $$$ for making your own quizzes and posts and other important BuzzFeed Community announcements!',
      frequency: 'monthly',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/9v5YhcFtZ.png',
      brand: 'buzzfeed',
      category: 'icymi',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-healthy-eating',
      slug: 'course-healthy-eating',
      title: 'Goodful’s Healthy Eating Challenge',
      description: 'Two weeks’ worth of healthy recipes with nutritional info.',
      frequency: '14 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/zE3vxsFOI.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_please-like-me',
      slug: 'please-like-me',
      title: 'Please Like Me',
      description: 'The girlies from the social news team show you the best (and worst) of influencers and internet culture in our weekly newsletter.',
      frequency: '1 update a week',
      image: 'https://www.buzzfeed.com/static-assets/img/pleaselikeme-banner.277b90dd4a17f1cfb527a414da5b499b.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_quibbles-bits',
      slug: 'quibbles-bits',
      title: 'Quibbles & Bits',
      description: 'Sign up for for deep dives about sensitive and inclusive language, style guidance, grammar memes, and more',
      frequency: '1 update a month',
      image: 'https://www.buzzfeed.com/static-assets/img/quibbleslanguage-banner.c2b8fc16b74bad7e375026c951f30e59.jpg',
      brand: 'buzzfeed',
      category: 'buzzfeed_news',
      editions: [
        'en-us'
      ]
    },
    {
      name: 'buzzfeed_email_course-butt-challenge',
      slug: 'course-butt-challenge',
      title: 'Butt Challenge',
      description: 'Develop a stronger butt in 30 days with the help of a fitness instructor!',
      frequency: '30 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Rlu-X345j.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-goodful-5k',
      slug: 'course-goodful-5k',
      title: 'Goodful’s 4 Weeks to 5K',
      description: 'A good place to start if you’ve ever thought about getting into running.',
      frequency: '28 days',
      image: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Hb2K6l6nT.png',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    },
    {
      name: 'buzzfeed_email_course-overcome-anxiety',
      slug: 'course-overcome-anxiety',
      title: 'Goodfuls 8-Day Guide to Overcoming Anxiety',
      description: 'Helping you develop healthy habits to make working through anxiety a lil bit easier.',
      frequency: '8 Days',
      image: 'https://www.buzzfeed.com/static-assets/img/goodful8dayanxiety-banner.887e3a68044068b57ac5103e5de9e7e0.jpg',
      brand: 'buzzfeed',
      category: 'courses',
      editions: [
        'en-us',
        'en-uk',
        'en-ca',
        'en-au',
        'en-in'
      ]
    }
  ]
};

export default mock;
