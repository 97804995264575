import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 200,
  height: 200,
  id: 'wtf-badge'
};

export const WtfBadge = () => (
  <symbol {...attributes}>
    <path d="M186.588 50.01c27.61 47.822 11.225 108.973-36.596 136.583-47.822 27.61-108.97 11.226-136.58-36.597-27.61-47.823-11.225-108.974 36.596-136.584 47.822-27.61 108.971-11.225 136.58 36.598"/>
    <path fill="#222" d="M88.008 139.938l-18.521-17.695 6.141 24.844-15.455 8.922-33.028-31.713 14.587-8.423 17.158 18.063-5.409-24.846 12.695-7.329 18.735 17.15-7.062-23.89 14.665-8.466 10.951 44.46-15.456 8.925M115.649 109.261l-8.513-14.748-6.308 3.643-7.102-12.3 6.308-3.643-6.01-10.409 14.192-8.194 6.011 10.41 7.729-4.462 7.101 12.3-7.727 4.463 6.146 10.645c1.139 1.971 3.311 2.82 5.203 1.728 1.262-.73 2.295-1.853 2.429-2.349l8.715 9.267c-.724 2.206-3.352 5.3-8.162 8.078-8.439 4.87-15.414 3.534-20.012-4.429M152.948 102.445l-14.888-25.786-6.308 3.641-7.101-12.3 6.308-3.644-1-1.734c-4.599-7.964-1.76-17.069 6.677-21.939 4.417-2.55 9.955-4.065 14.274-2.772l-.574 10.843c-1.458-.526-2.756-.406-4.333.505-1.812 1.047-2.945 3.277-1.85 5.17l1 1.735 7.728-4.463 7.103 12.3-7.728 4.464 14.889 25.786-14.197 8.194"/>
  </symbol>
);

export const UseWtfBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
