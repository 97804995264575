import { h } from 'preact';
import { I18n } from '../../shared/context';
import { ThemedLink } from '../../shared/link';

import styles from './styles.scss';

const reasons = [
  'to leave a comment!',
  'to make your own quizzes!',
  'to save your quiz results!',
  'to earn Internet Points!',
  'to earn trophies!',
];

const randomReason = () => {
  return reasons[Math.floor(Math.random() * reasons.length)];
};

function SignInPrompt({closeButton, showRandomLoginCta}) {
  return (
    <I18n.Consumer>
      {i18n => (
        <div className={styles.signInPrompt}>
          <div className={styles.closeButtonWrapper}>{closeButton}</div>
          <p className={styles.cta}>
            <ThemedLink className={styles.ctaLink} href="/auth/signin" location={'mainNav'} label={'login'}>
              {showRandomLoginCta ? 'Sign in to BuzzFeed' : i18n.log_in}
            </ThemedLink>
          </p>
          {showRandomLoginCta && (
            <p className={styles.sell}>{randomReason()}</p>
          )}
        </div>
      )}
    </I18n.Consumer>
  );
}

export default SignInPrompt;
