import { h, Component } from 'preact';
import auth from '@buzzfeed/buzzblocks/js/services/auth';
import { fetchExperiments } from '@buzzfeed/react-components/lib/utils/abeagle';
import { SignInModal } from '@buzzfeed/react-components/dist/module/lib/components/SignInModal';
import { getUserUUID } from '@buzzfeed/bf-utils/lib/userid';
import styles from './buzzfeed_header.scss';
import { PageOverlay, ThemedToggle } from './more-nav';
import { I18n, Config, Theme, Tracking } from '../shared/context';
import { ThemedMainNav } from './main-nav';
import { ThemedTopicNav } from './topic-nav';
import { SkipNav } from '../shared/skip-nav';
import { breakpointLarge } from '../sass/variables.scss';
import { UseCloseIcon } from '../shared/svg/icons/close';
import { UseHamburgerIcon } from '../shared/svg/icons/hamburger';
import { ThemedLogo } from './logo';
import { Button } from '../shared/button';
import { Search } from './search';
import { getTeaserNotifications } from '../shared/teaserNotifications';
import { isTooltipDismissed, setTooltipDismissed } from '../shared/tooltipDismissed';
import { NewsletterSignup } from '../shared/newsletter-signup';
import {
  createImpressionHandler,
  trackNavAction,
  trackNavClick,
  trackNavExperiments,
  trackNavSignInClick
} from './tracking/clientEventTracking';
import EXPERIMENT_CONFIG from '../constants/ab-tests';
import ThemedNotifUserMenu from './notif-user-menu';
import '@buzzfeed/react-components/dist/module/lib/components/SignInModal/signInModal.css';
import GoogleOneTap from '../shared/google-one-tap';

const isServer = () => typeof window === 'undefined';

function getUserInfo() {
  if (!auth.isLoggedIn()) {
    return null;
  }
  const userInfo = auth.getUserInfo();
  return {
    id: userInfo.userid,
    displayName: userInfo.display_name,
    image: userInfo.image,
    username: userInfo.username,
    isCommunityUser:
      !auth.userCan('general_admin') && !auth.userCan('freelance_contributors'),
    isContributorUser: auth.userCan('freelance_contributors')
  };
}

export default class BuzzFeedHeader extends Component {
  constructor() {
    super();
    this.state = {
      isSticking: false,
      logoutXsrf: null,
      notifMenuEnabled: false,
      overlayOffset: 0,
      redirectParam: '',
      modalIsOpen: false,
      signInPromptEnabled: false,
      showMoreNav: false,
      showNewsletterSignup: false,
      showNotifMenu: false,
      showNotifTooltip: false,
      teaserNotifications: null,
      tooltipDismissed: null,
      userInfo: null,
      userMenuEnabled: false,
      userOptionsEnabled: false,
    };
    this.setShowNewsletterSignup = this.setShowNewsletterSignup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setShowNotifToolotip = this.setShowNotifToolotip.bind(this);
    this.toggleMoreNav = this.toggleMoreNav.bind(this);
    this.toggleNotifMenu = this.toggleNotifMenu.bind(this);
    this.scrollObserver = null;
  }

  overlayTopOffset() {
    const baseNavOffset = 56;
    const containerHeight = this.stickyContainer.clientHeight;
    const { y: containerY } = this.stickyContainer.getBoundingClientRect();
    return this.state.isSticking
      ? containerHeight - baseNavOffset
      : containerHeight + containerY - baseNavOffset;
  }

  setShowNewsletterSignup(visible) {
    this.setState({
      ...this.state,
      showNewsletterSignup: visible,
    });
  }

  toggleMoreNav(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      showMoreNav: !this.state.showMoreNav,
      showNotifMenu: false,
    });
  }

  setShowNotifToolotip(state) {
    if (state !== this.state.showNotifTooltip) {
      this.setState({
        showNotifTooltip: state,
      });
    }
  }

  toggleNotifMenu({ forceVisible = !this.state.showNotifMenu }) {
    setTooltipDismissed();
    if (!this.state.showNotifMenu) {
      trackNavAction({
        location: this.state.userInfo
          ? 'notificationMenu'
          : 'teaserNotificationMenu',
        action_value: 'show',
      });
    }

    this.setState({
      ...this.state,
      showNotifMenu: forceVisible,
      showMoreNav: false,
      tooltipDismissed: true
    });
  }

  onLogout(e) {
    const signout = e.target.form;
    this.setLogoutXsrfFromCookies();
    if (!this.state.logoutXsrf) {
      // cant logout without xsrf token
      return;
    }
    signout.submit();
  }

  handleLogoutFormState() {
    if (!auth.isLoggedIn()) {
      return;
    }

    this.setLogoutXsrfFromCookies();
    if (!this.state.logoutXsrf) {
      // we have to go get a xsrf cookie
      fetch('/auth/signin').then(() => {
        this.setLogoutXsrfFromCookies();
      });
    }
  }

  setLogoutXsrfFromCookies() {
    document.cookie.split('; ').forEach((item) => {
      if (item.startsWith('_xsrf')) {
        this.setState({
          logoutXsrf: item.split('=')[1],
        });
      }
    });
  }

  /**
   * Listener for updating the sticky behavior when header is being scrolled into or out of view
   */
  createScrollObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.setState({
            isSticking: !entry.isIntersecting,
          });
        });
      },
      { threshold: 1 }
    );

    observer.observe(this.stickyContainer);
    return observer;
  }

  /**
   * TODO:
   * This is where we define which version of the UserMenu / Sign In link the
   * visitor gets based on whether they're logged in and whether they have a
   * teaser notification.
   *
   * Authed: Full user menu with user options and notifications
   * Unauthed no teaser notifs: Sign In link, no user menu
   * Unauthed teaser notifs: No sign in link, user menu with no user options but
   *                         with a Sign In Prompt and a notifications area.
   *
   * The *Enabled states here are dropped down to the relevant components in the
   * NotifUserMenu files.
   */
  componentDidMount() {
    const userInfo = getUserInfo();
    const teaserNotifications = getTeaserNotifications();
    const tooltipDismissed = isTooltipDismissed();

    const isLoggedIn = !!userInfo;
    const isWithNewsletterSignup = new URLSearchParams(window.location.search).get('newsletter_modal') === 'true';
    const isWithTeaserNotifications = teaserNotifications.length > 0;

    this.setState({
      ...this.state,
      userInfo,
      teaserNotifications,
      userMenuEnabled: isLoggedIn || isWithTeaserNotifications,
      notifMenuEnabled: isLoggedIn || isWithTeaserNotifications,
      userOptionsEnabled: isLoggedIn,
      showNewsletterSignup: isWithNewsletterSignup,
      signInPromptEnabled: !isLoggedIn && isWithTeaserNotifications,
      tooltipDismissed,
      redirectParam: `?redirect=${encodeURIComponent(window.location.href)}`,
    });

    this.handleLogoutFormState();

    // don't sticky the nav if we are zoomed in very closely or the nav will block a lot of the page
    if (window.innerHeight > this.stickyContainer.clientHeight * 3) {
      this.stickyContainer.style.height = `${this.stickyContainer.clientHeight}px`;
      this.scrollObserver = this.createScrollObserver();

      // check fixed height on resize
      if (window && window.matchMedia) {
        const stickyBreak = window.matchMedia(
          `(min-width: ${breakpointLarge})`
        );
        stickyBreak.addListener(() => {
          this.stickyContainer.style.height = '';
          window.requestAnimationFrame(() => {
            this.stickyContainer.style.height = `${this.stickyContainer.clientHeight}px`;
          });
        });
      }
    }

    if ('function' === typeof getUserUUID) {
      const userId = getUserUUID();
      fetchExperiments({
        userId,
        experimentConfig: EXPERIMENT_CONFIG,
        abeagleHost: this.props.config.abeagle_url,
        data: {},
        source: 'buzz_web',
      }).then((experiments) => {
        trackNavExperiments(experiments);
      }).catch((err) => console.error(err));
    }
  }

  componentWillUnmount() {
    this.scrollObserver.unobserve(this.stickyContainer);
  }

  componentDidUpdate() {
    // Update overlay position if any popups is opened
    if (this.state.showMoreNav) {
      if (this.state.overlayOffset !== this.overlayTopOffset()) {
        this.setState({
          ...this.state,
          overlayOffset: this.overlayTopOffset()
        });
      }
      this.checkElementPosition(this.stickyContainer, function() {
        this.setOverlayPosition();
      }.bind(this));
    } else {
      clearTimeout(this.stickyContainer.onElementPositionChangeTimer);
    }
  }

  setOverlayPosition() {
    this.setState({
      ...this.state,
      overlayOffset: this.overlayTopOffset()
    });
  }

  checkElementPosition(elm, callback) {
    let {bottom} = elm.getBoundingClientRect();

    (function run() {
      let rect = elm.getBoundingClientRect();

      if(bottom !== rect.bottom) {
        callback();
      }
      bottom = rect.bottom;
      if(elm.onElementPositionChangeTimer) {
        clearTimeout(elm.onElementPositionChangeTimer);
      }
      elm.onElementPositionChangeTimer = setTimeout(run, 200);
    })();
  }

  openModal() {
    this.setState({
      modalIsOpen: true
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  render({ navItems, config, edition, i18n, theme }, state) {
    return (
      <I18n.Provider value={i18n}>
        <Config.Provider value={config}>
          <Theme.Provider value={theme}>
            <Tracking.Provider value={{ createImpressionHandler, trackNavAction, trackNavClick }}>
              {!isServer() &&
                <SignInModal
                  isOpen={this.state.modalIsOpen}
                  onClose={this.closeModal}
                  track={{
                    contentAction: ({ action_type, action_value }) => trackNavAction({
                      label: 'signInModal',
                      location: 'signInModal',
                      action_type,
                      action_value
                    }),
                    externalLink: (eventData) => trackNavSignInClick(eventData),
                  }}
                />
              }
              <header className={styles.header}>
                <SkipNav/>
                <div
                  className="js-sticky-container bf-sticky-container"
                  ref={(el) => (this.stickyContainer = el)}
                >
                  <ThemedMainNav
                    navItems={navItems}
                    showMoreNav={state.showMoreNav}
                    isSticking={state.isSticking}
                    allTopics={navItems.topics}
                  >
                    <ThemedToggle
                      onClick={this.toggleMoreNav}
                      aria-label={i18n.hamburger}
                    >
                      {state.showMoreNav ? (
                        <UseCloseIcon width={22} height={22} aria-hidden={true}/>
                      ) : (
                        <UseHamburgerIcon
                          width={16}
                          height={12}
                          aria-hidden={true}
                        />
                      )}
                    </ThemedToggle>
                    <div className={`${styles.logoWrapper} ${state.isSticking ? styles.isSticking : ''}`}>
                      <ThemedLogo edition={edition}/>
                    </div>
                    <div
                      className={`${styles.navIconToggles} ${
                        state.userInfo ? styles.hasUserInfo : ''
                      }`}
                    >
                      <GoogleOneTap edition={edition}/>
                      <Search/>
                      {state.userMenuEnabled ? (<ThemedNotifUserMenu
                        isVisible={state.showNotifMenu}
                        notifMenuEnabled={state.notifMenuEnabled}
                        userOptionsEnabled={state.userOptionsEnabled}
                        tooltipDismissed={state.tooltipDismissed}
                        userInfo={state.userInfo}
                        signInPromptEnabled={state.signInPromptEnabled}
                        teaserNotifications={state.teaserNotifications}
                        onUserMenuClicked={this.toggleNotifMenu}
                        setShowNotifToolotip={(value) =>
                          this.setShowNotifToolotip(value)
                        }
                        onNotifTooltipClicked={() =>
                          this.toggleNotifMenu({forceVisible: true})
                        }
                        onLogoutClicked={this.onLogout}
                        bfURL={config.bf_url}
                        edition={edition}
                        logoutXsrf={state.logoutXsrf}
                      />) : (<Button
                        onClick={this.openModal}
                        className={styles.navLoginLink}>
                        {i18n.log_in}
                      </Button>)}
                    </div>
                  </ThemedMainNav>
                </div>
                <ThemedTopicNav isSticking={state.isSticking} topics={navItems.topics} isSecondNav={true}/>
                {state.showNewsletterSignup && (
                  <NewsletterSignup
                    edition={edition}
                    onHide={() => this.setShowNewsletterSignup(false)}
                    track={{
                      contentAction: trackNavAction
                    }}
                  />
                )}
                {state.showMoreNav && (
                  <PageOverlay
                    top={state.overlayOffset}
                    onHide={this.toggleMoreNav}
                  />
                )}
                {state.showNotifMenu && (
                  <PageOverlay
                    top={state.overlayOffset}
                    onHide={this.toggleNotifMenu}
                  />
                )}
              </header>
            </Tracking.Provider>
          </Theme.Provider>
        </Config.Provider>
      </I18n.Provider>
    );
  }
}
