import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 500,
  height: 500,
  id: 'oishii-badge',
  fill: 'none'
};

export const OishiiBadge = () => (
  <symbol {...attributes}>
    <g clip-path="url(#clip0_13_194)">
      <path d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z" fill="#FFF000"/>
      <path d="M124.9 305.5L106.3 316.2L109.5 321.7C112.3 319.5 115.1 317.5 118.3 315.7C125.6 311.5 135.2 307.8 143.7 309.2C151.9 310.6 158.3 315.3 162.4 322.4C167.9 331.9 167.5 343.5 160.9 352.4C156.6 358.2 150 362.8 143.8 366.4L133.9 354.4C138.5 351.6 144.4 347.5 146.9 342.7C148.7 339.2 147.8 335 145.9 331.7C141.3 323.7 132.9 325.1 126 329.1C123.1 330.8 120 333.1 117.4 335.3L128.4 354.4C134.1 364.3 129.4 372.2 120.3 377.4C114.8 380.6 108.7 382.2 102.3 380.5C97.1 379 92.5 375.2 89.8 370.5C85.6 363.2 85.7 354.3 88.7 346.9C90.8 341.9 93.4 337.8 96.6 333.5L91.6 324.8L72.6 335.8L64.4 321.6L83.4 310.6L78.7 302.4L93.5 293.8L98.2 302L114.4 292.6C113.6 292.4 112.8 292.2 111.8 292.1L119.6 278.1C130.4 279.7 140.6 284.7 149.5 290.8L141.2 306.1C133.9 301.3 126.3 296.9 118.1 293.8L124.9 305.5ZM103.6 361.3C105.2 364.1 108.2 364.7 110.7 363.2C112.7 362.1 112.5 360.8 111.7 359.4L105 347.9C102.5 351.7 101.1 356.9 103.6 361.3Z" fill="black"/>
      <path d="M166.8 257.2C170.8 267.6 175 277.8 180.6 287.4C183.1 291.7 194 310.6 200.2 307C202.3 305.8 202.5 303 202.5 300.8C202.6 296.4 201.5 290.1 200.6 285.8L217.5 286.2C219.9 298.3 222.6 317.9 209.8 325.3C190.9 336.2 172.3 308.5 164.4 294.8C158.9 285.2 154.2 275.1 149.6 265.1L166.8 257.2ZM248.4 265.7C252.7 271.6 256.6 277.8 260.2 284.1L244 294.6C242.9 292.4 241.8 290.3 240.5 288.2C234.7 278.1 227.1 268 218.3 260.3C212.6 255.3 205.9 251.7 198.5 250.4L206.3 235C226.5 240.2 236.5 249.3 248.4 265.7Z" fill="black"/>
      <path d="M255.9 200.8C260.5 210 265.4 219 270.2 227.9C273.7 234.3 277.1 240.6 280.7 246.9C286.3 256.7 291.4 260.9 302.2 254.6C310.6 249.7 315.3 242.6 314.9 232.7C314.7 226.6 312.5 220.3 310.5 214.6L329.1 213.2C332.8 224.5 334.7 235.6 331 247.2C327.6 258.1 320.3 264.4 310.6 270C303.1 274.3 294.9 277.5 286.2 275.8C276 273.7 269 263.5 264.2 255C255.6 240.2 247.4 225.1 238.8 210.2L255.9 200.8Z" fill="black"/>
      <path d="M335.5 159.8C339.5 170.2 343.7 180.4 349.2 190C351.7 194.3 362.6 213.2 368.8 209.6C370.9 208.4 371.1 205.6 371.1 203.4C371.2 199 370.1 192.7 369.2 188.4L386.1 188.8C388.5 200.9 391.2 220.5 378.4 227.9C359.5 238.8 340.9 211.1 333 197.4C327.5 187.8 322.8 177.7 318.2 167.7L335.5 159.8ZM417.1 168.3C421.4 174.2 425.3 180.4 428.9 186.7L412.7 197.2C411.6 195 410.5 192.9 409.2 190.8C403.4 180.7 395.8 170.6 387 162.9C381.3 157.9 374.6 154.3 367.2 153L375 137.6C395.2 142.9 405.1 151.9 417.1 168.3Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_13_194">
        <rect width="500" height="500" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
);

export const UseOishiiBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
