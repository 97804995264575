import { h, Component } from 'preact';
import styles from './styles.scss';

export class NavLabel extends Component {
  render({ children, navLabelType, ...props }) {
    if (!navLabelType) {
      return null;
    }
    const labelStyle = styles[navLabelType] ? styles[navLabelType] : '';
    return (
      <span className={`${styles.navLabel} ${labelStyle}`} {...props}>{children}</span>
    );
  }
}
