import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 500,
  height: 500,
  id: 'trending-jp-badge',
  fill: 'none'
};

export const TrendingJpBadge = () => (
  <symbol {...attributes}>
    <g clip-path="url(#clip0_13_216)">
      <path d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z" fill="#EE3322"/>
      <path d="M234 417.9C234 417.9 210.2 426.9 164.6 416.8C119 406.7 87.9 357.1 85.6 313.6C82.4 255.4 130.8 214.7 130.8 214.7L145.2 203L147.4 167.1C147.7 161.9 153 158.6 157.8 160.6L191.5 174.6L218.8 158.9C218.8 158.9 216 117.4 223 113.4C230 109.4 271.1 132.2 271.1 132.2C271.1 132.2 315.4 116.8 360 132.4C405.6 148.4 450.8 207.7 439.3 256.6C427.8 305.5 405.4 320.2 405.4 320.2L378.4 344.3C349.2 378.6 309 391.2 265.8 414.1L234 417.9Z" fill="white"/>
      <path d="M164.6 416.8C119.3 405.7 87.9 357.1 85.6 313.6C82.4 255.4 130.8 214.7 130.8 214.7L145.2 203L147.4 167.1C147.7 161.9 153 158.6 157.8 160.6L191.5 174.6L218.8 158.9C218.8 158.9 216 117.4 223 113.4C230 109.4 271.1 132.2 271.1 132.2C271.1 132.2 315.4 116.8 360 132.4C405.6 148.4 446.4 208.8 434.9 257.8C430.3 277.5 422.3 299.6 396.8 327.6C357.9 370.2 311 395.7 261.2 412.5C224 425 194.7 424.1 164.6 416.8Z" stroke="black" stroke-width="7.9598" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M326.081 280.359C344.83 269.535 348.97 241.606 335.328 217.979C321.687 194.352 295.43 183.974 276.681 194.799C257.933 205.623 253.793 233.552 267.434 257.179C281.075 280.806 307.333 291.184 326.081 280.359Z" fill="white" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <mask id="mask0_13_216" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="265" y="197" width="96" height="84">
        <path d="M355.812 263.714C366.636 244.965 356.257 218.709 332.63 205.069C309.003 191.428 281.075 195.569 270.252 214.318C259.428 233.066 269.807 259.323 293.434 272.963C317.061 286.603 344.989 282.462 355.812 263.714Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_13_216)">
        <path d="M331.67 254.26C348.218 244.705 351.873 220.055 339.833 199.202C327.794 178.349 304.618 169.19 288.07 178.744C271.521 188.299 267.866 212.949 279.906 233.802C291.946 254.655 315.121 263.814 331.67 254.26Z" fill="#EE3322" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <mask id="mask1_13_216" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="259" y="190" width="85" height="95">
        <path d="M326.081 280.359C344.83 269.535 348.97 241.606 335.328 217.979C321.687 194.352 295.43 183.974 276.681 194.799C257.933 205.623 253.793 233.552 267.434 257.179C281.075 280.806 307.333 291.184 326.081 280.359Z" fill="white"/>
      </mask>
      <g mask="url(#mask1_13_216)">
        <path d="M324.9 224.1C314.5 232.9 313.8 235.6 318.8 248.9C309.8 238 307.1 237.3 294.3 241.8C304.7 233 305.4 230.3 300.4 217C309.3 228 312 228.7 324.9 224.1Z" fill="#FFF000"/>
        <path d="M324.9 224.1C314.5 232.9 313.8 235.6 318.8 248.9C309.8 238 307.1 237.3 294.3 241.8C304.7 233 305.4 230.3 300.4 217C309.3 228 312 228.7 324.9 224.1Z" stroke="#FFF000" stroke-width="1.3543" stroke-miterlimit="10" stroke-linejoin="round"/>
      </g>
      <path d="M326.081 280.359C344.83 269.535 348.97 241.606 335.328 217.979C321.687 194.352 295.43 183.974 276.681 194.799C257.933 205.623 253.793 233.552 267.434 257.179C281.075 280.806 307.333 291.184 326.081 280.359Z" stroke="black" stroke-width="8.65401" stroke-miterlimit="10"/>
      <path d="M307.8 215.4C311.942 215.4 315.3 212.042 315.3 207.9C315.3 203.758 311.942 200.4 307.8 200.4C303.658 200.4 300.3 203.758 300.3 207.9C300.3 212.042 303.658 215.4 307.8 215.4Z" fill="white" stroke="black" stroke-width="4.3271" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M208.714 348.176C227.463 337.351 231.603 309.422 217.962 285.795C204.32 262.168 178.063 251.79 159.314 262.615C140.566 273.44 136.426 301.368 150.067 324.995C163.709 348.622 189.966 359 208.714 348.176Z" fill="white" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <mask id="mask2_13_216" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="147" y="264" width="95" height="85">
        <path d="M237.362 331.118C248.186 312.37 237.807 286.114 214.18 272.473C190.553 258.833 162.625 262.974 151.801 281.723C140.977 300.471 151.356 326.728 174.983 340.368C198.61 354.008 226.538 349.867 237.362 331.118Z" fill="white"/>
      </mask>
      <g mask="url(#mask2_13_216)">
        <path d="M213.007 323.962C229.555 314.408 233.21 289.758 221.17 268.905C209.13 248.052 185.955 238.892 169.407 248.447C152.858 258.002 149.203 282.652 161.243 303.505C173.283 324.358 196.458 333.517 213.007 323.962Z" fill="#EE3322" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <mask id="mask3_13_216" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="142" y="258" width="84" height="95">
        <path d="M208.714 348.176C227.463 337.351 231.603 309.422 217.962 285.795C204.32 262.168 178.063 251.79 159.314 262.615C140.566 273.44 136.426 301.368 150.067 324.995C163.709 348.622 189.966 359 208.714 348.176Z" fill="white"/>
      </mask>
      <g mask="url(#mask3_13_216)">
        <path d="M204.5 293.6C194.1 302.4 193.4 305.1 198.4 318.4C189.4 307.5 186.7 306.8 173.9 311.3C184.3 302.5 185 299.8 180 286.5C189 297.5 191.6 298.2 204.5 293.6Z" fill="#FFF000"/>
        <path d="M204.5 293.6C194.1 302.4 193.4 305.1 198.4 318.4C189.4 307.5 186.7 306.8 173.9 311.3C184.3 302.5 185 299.8 180 286.5C189 297.5 191.6 298.2 204.5 293.6Z" stroke="#FFF000" stroke-width="1.3543" stroke-miterlimit="10" stroke-linejoin="round"/>
      </g>
      <path d="M208.714 348.176C227.463 337.351 231.603 309.422 217.962 285.795C204.32 262.168 178.063 251.79 159.314 262.615C140.566 273.44 136.426 301.368 150.067 324.995C163.709 348.622 189.966 359 208.714 348.176Z" stroke="black" stroke-width="8.65401" stroke-miterlimit="10"/>
      <path d="M190.4 283.2C194.542 283.2 197.9 279.842 197.9 275.7C197.9 271.558 194.542 268.2 190.4 268.2C186.258 268.2 182.9 271.558 182.9 275.7C182.9 279.842 186.258 283.2 190.4 283.2Z" fill="white" stroke="black" stroke-width="4.3271" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M260 338.9C260 338.9 276.9 357.9 289.2 349.9C301.5 341.9 288.5 316.5 288.5 316.5C288.5 316.5 272.8 319.4 268.2 314.8C268.2 314.8 259.1 327.1 255.4 328.5" fill="#F8DDD0"/>
      <path d="M242.2 331.4C242.2 331.4 253 337 261.3 322C266.6 312.4 269.4 315.6 273.8 317.3C278.2 319 293.7 315.1 294.9 302.6" stroke="black" stroke-width="7.5724" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M260 338.9C260 338.9 276.9 357.9 289.2 349.9C301.5 341.9 288.5 316.5 288.5 316.5" stroke="black" stroke-width="7.5724" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M255.985 299.72C258.089 298.505 259.303 296.667 258.695 295.615C258.088 294.562 255.889 294.694 253.785 295.909C251.68 297.124 250.467 298.962 251.074 300.015C251.682 301.067 253.88 300.935 255.985 299.72Z" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M183 230.3C183 230.3 194.1 229 198.5 232.3" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M236.2 199.5C236.2 199.5 229.5 208.4 230.2 214" stroke="black" stroke-width="6.4907" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M167.3 407C178.401 407 187.4 398.001 187.4 386.9C187.4 375.799 178.401 366.8 167.3 366.8C156.199 366.8 147.2 375.799 147.2 386.9C147.2 398.001 156.199 407 167.3 407Z" fill="#F8DDD0"/>
      <path d="M383.6 282.1C394.701 282.1 403.7 273.101 403.7 262C403.7 250.899 394.701 241.9 383.6 241.9C372.499 241.9 363.5 250.899 363.5 262C363.5 273.101 372.499 282.1 383.6 282.1Z" fill="#F8DDD0"/>
      <path d="M304.817 116.819L202.793 149.058L205.986 159.165L308.011 126.926L304.817 116.819Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M229.1 61.3C232.745 61.3 235.7 58.3451 235.7 54.7C235.7 51.055 232.745 48.1 229.1 48.1C225.455 48.1 222.5 51.055 222.5 54.7C222.5 58.3451 225.455 61.3 229.1 61.3Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M174.3 92.2C177.945 92.2 180.9 89.2451 180.9 85.6C180.9 81.9549 177.945 79 174.3 79C170.655 79 167.7 81.9549 167.7 85.6C167.7 89.2451 170.655 92.2 174.3 92.2Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M290.3 55.6C293.945 55.6 296.9 52.6451 296.9 49C296.9 45.3549 293.945 42.4 290.3 42.4C286.655 42.4 283.7 45.3549 283.7 49C283.7 52.6451 286.655 55.6 290.3 55.6Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M302.3 108.7L292.2 65.2L263.9 89.5L234.9 72.9L220.6 103.2L183.5 99.5L200.2 141L302.3 108.7Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M244.3 111.3C249.712 111.3 254.1 106.912 254.1 101.5C254.1 96.0876 249.712 91.7 244.3 91.7C238.888 91.7 234.5 96.0876 234.5 101.5C234.5 106.912 238.888 111.3 244.3 111.3Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M206.2 122.4C209.9 122.4 212.9 119.4 212.9 115.7C212.9 112 209.9 109 206.2 109C202.5 109 199.5 112 199.5 115.7C199.5 119.4 202.5 122.4 206.2 122.4Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M286.4 98.4C290.1 98.4 293.1 95.4003 293.1 91.7C293.1 87.9997 290.1 85 286.4 85C282.7 85 279.7 87.9997 279.7 91.7C279.7 95.4003 282.7 98.4 286.4 98.4Z" stroke="#231814" stroke-width="17.6051" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M304.817 116.819L202.793 149.058L205.986 159.165L308.011 126.926L304.817 116.819Z" fill="#FFF000"/>
      <path d="M229.1 61.3C232.745 61.3 235.7 58.3451 235.7 54.7C235.7 51.055 232.745 48.1 229.1 48.1C225.455 48.1 222.5 51.055 222.5 54.7C222.5 58.3451 225.455 61.3 229.1 61.3Z" fill="#FFF000"/>
      <path d="M174.3 92.2C177.945 92.2 180.9 89.2451 180.9 85.6C180.9 81.9549 177.945 79 174.3 79C170.655 79 167.7 81.9549 167.7 85.6C167.7 89.2451 170.655 92.2 174.3 92.2Z" fill="#FFF000"/>
      <path d="M290.3 55.6C293.945 55.6 296.9 52.6451 296.9 49C296.9 45.3549 293.945 42.4 290.3 42.4C286.655 42.4 283.7 45.3549 283.7 49C283.7 52.6451 286.655 55.6 290.3 55.6Z" fill="#FFF000"/>
      <path d="M302.3 108.7L292.2 65.2L263.9 89.5L234.9 72.9L220.6 103.2L183.5 99.5L200.2 141L302.3 108.7Z" fill="#FFF000"/>
      <path d="M244.3 111.3C249.712 111.3 254.1 106.912 254.1 101.5C254.1 96.0876 249.712 91.7 244.3 91.7C238.888 91.7 234.5 96.0876 234.5 101.5C234.5 106.912 238.888 111.3 244.3 111.3Z" fill="black"/>
      <path d="M206.2 122.4C209.9 122.4 212.9 119.4 212.9 115.7C212.9 112 209.9 109 206.2 109C202.5 109 199.5 112 199.5 115.7C199.5 119.4 202.5 122.4 206.2 122.4Z" fill="black"/>
      <path d="M286.4 98.4C290.1 98.4 293.1 95.4003 293.1 91.7C293.1 87.9997 290.1 85 286.4 85C282.7 85 279.7 87.9997 279.7 91.7C279.7 95.4003 282.7 98.4 286.4 98.4Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_13_216">
        <rect width="500" height="500" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
);

export const UseTrendingJpBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
