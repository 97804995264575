import { h } from 'preact';
import { SvgUse } from '..';
//Duplicated logo with different linearGradient ids so we can render it two times on the page
const attributes = {
  id: 'pyramid-scheme-logo-2',
  width: 114,
  height: 9,
};

export const PyramidScheme = (props) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props}>
    <symbol {...attributes}>
      <path d="M2.01052 9C3.58666 9 4.03345 8.6087 4.03345 8.06324C4.03345 7.77866 3.90934 7.56522 3.57425 7.31621C3.31363 7.12648 3.10265 6.85376 3.10265 6.43874V5.79842C3.10265 5.66799 3.15229 5.59684 3.25158 5.59684C3.53702 5.59684 3.90934 6.083 4.6788 6.083C6.11843 6.083 7.35949 4.80237 7.35949 3.083C7.35949 1.26877 6.317 0.142293 3.97139 0.142293H1.6382C0.384729 0.142293 0 0.474308 0 0.98419C0 1.2332 0.124106 1.44664 0.40955 1.73123C0.645351 1.96838 0.794279 2.28854 0.794279 3.10672V6.07115C0.794279 6.77075 0.645351 7.09091 0.421961 7.28063C0.148927 7.5415 0 7.79051 0 8.08696C0 8.63241 0.632941 9 2.01052 9ZM3.8597 4.89723C3.46256 4.89723 3.10265 4.70751 3.10265 4.31621V2.00395C3.10265 1.57708 3.25158 1.39921 3.72318 1.39921C4.443 1.39921 4.95183 2.05138 4.95183 3.13044C4.95183 4.28063 4.48023 4.89723 3.8597 4.89723Z" fill="url(#paint0_linear_149_29_2)"/>
      <path d="M11.4798 9C12.9318 9 13.4283 8.6087 13.4283 8.0751C13.4283 7.77866 13.2917 7.58893 13.0063 7.29249C12.7953 7.06719 12.634 6.67589 12.634 6.16601V5.35968C13.2421 4.52964 14.2598 3.13044 14.6693 2.54941C14.9051 2.21739 15.1781 1.92095 15.4512 1.73123C15.8111 1.43478 15.9352 1.25692 15.9352 0.960475C15.9352 0.320158 15.2898 0.0237152 14.0736 0.0237152C12.845 0.0237152 12.2492 0.296443 12.2492 0.83004C12.2492 1.11462 12.4106 1.26877 12.5843 1.39921C12.7829 1.55336 12.9691 1.71937 12.9691 1.90909C12.9691 2.12253 12.8822 2.32411 12.6588 2.6087C12.3858 3.03557 12.1003 3.48617 11.7901 3.93676C11.4674 3.47431 11.1323 3 10.8344 2.53755C10.6483 2.3004 10.5366 2.14625 10.5366 1.89723C10.5366 1.69565 10.6607 1.5415 10.822 1.42293C11.033 1.28063 11.1571 1.12648 11.1571 0.865613C11.1571 0.343873 10.6855 0.0237152 9.09695 0.0237152C7.80625 0.0237152 7.08644 0.355731 7.08644 0.901186C7.08644 1.24506 7.18572 1.42292 7.5084 1.66008C7.7442 1.8498 7.89313 2.02767 8.33991 2.64427C8.87356 3.41502 9.82918 4.64822 10.3132 5.39526V6.16601C10.3132 6.67589 10.1643 7.06719 9.95329 7.28063C9.68025 7.55336 9.51892 7.7668 9.51892 8.0751C9.51892 8.6087 10.0278 9 11.4798 9Z" fill="url(#paint1_linear_149_29_2)"/>
      <path d="M24.1 5.53755C23.9511 5.53755 23.827 5.63241 23.6656 5.85771C23.4919 6.05929 23.2685 6.23715 23.0203 6.23715C22.648 6.23715 22.4246 5.96443 21.8413 4.80237C22.8838 4.31621 23.7649 3.43874 23.7649 2.32411C23.7649 1.04348 22.6852 0.142293 20.5257 0.142293H17.7458C16.4923 0.142293 16.1076 0.474308 16.1076 0.98419C16.1076 1.2332 16.2317 1.44664 16.5171 1.73123C16.7529 1.96838 16.9018 2.28854 16.9018 3.10672V6.07115C16.9018 6.77075 16.7529 7.09091 16.5295 7.28063C16.2565 7.5415 16.1076 7.79051 16.1076 8.08696C16.1076 8.63241 16.666 9 18.0188 9C19.5453 9 20.0293 8.62055 20.0293 8.08696C20.0293 7.79051 19.868 7.56522 19.6074 7.30435C19.3964 7.07905 19.2102 6.78261 19.2102 6.29644V5.59684C19.2102 5.3004 19.3591 5.1581 19.5329 5.1581C19.7439 5.1581 19.8928 5.28854 20.1162 5.78656C21.1215 7.9921 21.9778 9 22.8341 9C24.0504 9 24.6088 7.67194 24.6088 6.50988C24.6088 5.95257 24.4227 5.53755 24.1 5.53755ZM19.5825 4.25692C19.3467 4.25692 19.2102 4.17391 19.2102 3.93676V1.77866C19.2102 1.50593 19.3219 1.37549 19.8556 1.37549C20.6995 1.37549 21.2828 1.74308 21.2828 2.49012C21.2828 3.5336 20.2899 4.25692 19.5825 4.25692Z" fill="url(#paint2_linear_149_29_2)"/>
      <path d="M33.3505 7.29249C33.0154 7.06719 32.8789 6.85375 32.7176 6.34387L30.9677 0.98419C30.7567 0.320158 30.4465 0.142293 29.5777 0.142293H27.5548C26.5868 0.142293 26.2393 0.343873 26.2393 0.87747C26.2393 1.44664 26.5247 1.75494 27.108 1.86166L27.2197 1.88538L25.7056 6.37945C25.5691 6.78261 25.3457 7.09091 24.9361 7.42293C24.5886 7.6838 24.477 7.87352 24.477 8.13439C24.477 8.71542 25.0602 9 26.2641 9C27.5424 9 28.1008 8.71542 28.1008 8.16996C28.1008 7.88538 27.9395 7.71937 27.6665 7.55336C27.4679 7.42293 27.3314 7.29249 27.3314 7.10277C27.3314 6.99605 27.3562 6.88933 27.381 6.81818L27.5424 6.35573C27.6168 6.13044 27.7409 6.03557 28.0264 6.03557H29.7515C30.0493 6.03557 30.1982 6.16601 30.2851 6.40316L30.3844 6.72332C30.4216 6.81818 30.4465 6.94862 30.4465 7.05534C30.4465 7.25692 30.2975 7.43478 30.0865 7.56522C29.7763 7.7668 29.677 7.88538 29.677 8.20553C29.677 8.76285 30.2975 9 31.7868 9C33.2513 9 33.8966 8.71542 33.8966 8.06324C33.8966 7.7668 33.7725 7.57708 33.3505 7.29249ZM28.8455 1.95652L29.8135 4.92095H27.9519L28.8455 1.95652Z" fill="url(#paint3_linear_149_29_2)"/>
      <path d="M35.7088 9C37.1857 9 37.6325 8.59684 37.6325 8.06324C37.6325 7.73123 37.4339 7.51779 37.1981 7.28063C36.9747 7.07905 36.8258 6.71146 36.8258 6.05929V3.16601L38.7618 6.80632C38.8735 7.00791 38.9976 7.09091 39.1466 7.09091C39.3451 7.09091 39.4444 6.98419 39.5437 6.80632L41.5418 3.14229V6.01186C41.5418 6.71146 41.3929 7.07905 41.1819 7.28063C40.9337 7.51779 40.7475 7.73123 40.7475 8.0751C40.7475 8.59684 41.2439 9 42.7208 9C44.0239 9 44.5576 8.59684 44.5576 8.0751C44.5576 7.74308 44.4211 7.56522 44.1356 7.28063C43.9246 7.09091 43.7633 6.74704 43.7633 6.01186V2.96443C43.7633 2.35968 43.8874 1.96838 44.1356 1.70751C44.4211 1.42293 44.5824 1.20949 44.5824 0.889328C44.5824 0.533597 44.297 0.142293 43.3413 0.142293H42.5595C41.7652 0.142293 41.3432 0.41502 40.9213 1.20949L39.4568 4.06719L38.0296 1.20949C37.6697 0.486166 37.1857 0.142293 36.379 0.142293H35.2745C34.3685 0.142293 34.021 0.498024 34.021 0.913044C34.021 1.22134 34.1575 1.42293 34.4553 1.70751C34.716 1.95652 34.8277 2.35968 34.8277 2.96443V6.05929C34.8277 6.74704 34.6663 7.07905 34.4678 7.28063C34.1823 7.56522 34.0334 7.74308 34.0334 8.06324C34.0334 8.59684 34.3933 9 35.7088 9Z" fill="url(#paint4_linear_149_29_2)"/>
      <path d="M47.0653 9C48.5546 9 49.0262 8.59684 49.0262 8.0751C49.0262 7.77866 48.8896 7.57708 48.5918 7.28063C48.3932 7.09091 48.2195 6.77075 48.2195 5.98814V3.01186C48.2195 2.22925 48.3932 1.86166 48.5918 1.67194C48.8896 1.38735 49.0262 1.17391 49.0262 0.87747C49.0262 0.391304 48.5421 0.0237152 47.0653 0.0237152C45.6008 0.0237152 45.1168 0.391304 45.1168 0.87747C45.1168 1.17391 45.2533 1.38735 45.5388 1.67194C45.7498 1.87352 45.9111 2.22925 45.9111 3.01186V5.98814C45.9111 6.77075 45.7498 7.07905 45.5388 7.28063C45.2533 7.57708 45.1168 7.77866 45.1168 8.0751C45.1168 8.59684 45.6008 9 47.0653 9Z" fill="url(#paint5_linear_149_29_2)"/>
      <path d="M53.1453 8.85771C56.4837 8.85771 58.0847 6.96047 58.0847 4.28063C58.0847 1.51779 56.2728 0.142293 53.3315 0.142293H50.8866C50.0302 0.142293 49.571 0.474309 49.571 0.960475C49.571 1.24506 49.7324 1.48221 49.993 1.75494C50.2164 1.96838 50.3653 2.32411 50.3653 3.03557V5.94071C50.3653 6.68775 50.2164 7.00791 49.9806 7.25692C49.7076 7.5415 49.571 7.80237 49.571 8.06324C49.571 8.62055 50.0426 8.85771 50.8617 8.85771H53.1453ZM53.5797 7.57708C52.9715 7.57708 52.6737 7.17391 52.6737 6.43874V2.53755C52.6737 1.79051 52.9095 1.41107 53.5921 1.41107C54.8207 1.41107 55.6026 2.6087 55.6026 4.26877C55.6026 6.35573 54.709 7.57708 53.5797 7.57708Z" fill="url(#paint6_linear_149_29_2)"/>
      <path d="M63.0571 9C63.5163 9 63.7893 8.65613 64.2733 8.65613C64.7822 8.65613 65.3655 9 66.1349 9C67.9593 9 69.1755 7.57708 69.1755 6.15415C69.1755 4.89723 68.3936 4.1502 66.2342 3.3913C64.658 2.84585 64.1864 2.49012 64.1864 1.8498C64.1864 1.29249 64.5836 0.901186 65.2662 0.901186C66.0729 0.901186 66.5072 1.47036 67.0533 2.25296C67.438 2.81028 67.7607 3.2253 68.2075 3.2253C68.6294 3.2253 68.8652 2.78656 68.8652 2.11067C68.8652 1.03162 68.3936 0 67.7359 0C67.2891 0 66.954 0.355732 66.6686 0.711463C66.4079 0.41502 65.7998 0 64.6829 0C62.8213 0 61.7912 1.17391 61.7912 2.47826C61.7912 3.74704 62.5607 4.49407 64.6829 5.21739C66.1845 5.71542 66.6437 6.05929 66.6437 6.65217C66.6437 7.38735 66.0977 7.8498 65.3034 7.8498C64.3354 7.8498 63.6652 7.32806 62.9826 6C62.7096 5.47826 62.4738 5.03953 62.0642 5.03953C61.605 5.03953 61.3692 5.64427 61.3692 6.45059C61.3692 7.62451 62.0146 9 63.0571 9Z" fill="url(#paint7_linear_149_29_2)"/>
      <path d="M73.7122 9C75.7227 9 77.2988 7.62451 77.2988 6.32016C77.2988 5.89328 77.0506 5.51383 76.7776 5.51383C76.6659 5.51383 76.5542 5.6087 76.4549 5.83399C75.9709 6.78261 75.3255 7.28063 74.4816 7.28063C73.2033 7.28063 72.2477 5.73913 72.2477 3.55731C72.2477 2.0751 72.8558 1.1502 73.6749 1.1502C74.283 1.1502 74.705 1.51779 75.2635 2.50198C75.7847 3.45059 76.0702 3.73518 76.4549 3.73518C76.9389 3.73518 77.274 3.17787 77.274 2.25296C77.274 0.98419 76.7652 0 76.0826 0C75.7227 0 75.3628 0.26087 74.916 0.901186C74.5188 0.308301 73.9107 0 73.1288 0C71.2052 0 69.7532 1.86166 69.7532 4.37549C69.7532 7.11462 71.3665 9 73.7122 9Z" fill="url(#paint8_linear_149_29_2)"/>
      <path d="M85.9628 3.01186C85.9628 2.22925 86.1241 1.86166 86.3351 1.67194C86.6205 1.38735 86.7694 1.17391 86.7694 0.87747C86.7694 0.391304 86.273 0.0237152 84.8086 0.0237152C83.3317 0.0237152 82.8477 0.391304 82.8477 0.87747C82.8477 1.17391 82.9842 1.38735 83.2821 1.67194C83.4806 1.87352 83.6544 2.22925 83.6544 3.01186V3.78261H80.8248V3.01186C80.8248 2.22925 80.9985 1.86166 81.1971 1.67194C81.4949 1.38735 81.6315 1.17391 81.6315 0.87747C81.6315 0.391304 81.1474 0.0237152 79.6706 0.0237152C78.2061 0.0237152 77.7221 0.391304 77.7221 0.87747C77.7221 1.17391 77.8586 1.38735 78.1441 1.67194C78.355 1.87352 78.5164 2.22925 78.5164 3.01186V5.98814C78.5164 6.77075 78.355 7.07905 78.1441 7.28063C77.8586 7.57708 77.7221 7.77866 77.7221 8.0751C77.7221 8.59684 78.2061 9 79.6706 9C81.1474 9 81.6315 8.59684 81.6315 8.0751C81.6315 7.77866 81.4949 7.57708 81.1971 7.28063C80.9985 7.09091 80.8248 6.77075 80.8248 5.98814V4.99209H83.6544V5.98814C83.6544 6.77075 83.4806 7.07905 83.2821 7.28063C82.9842 7.57708 82.8477 7.77866 82.8477 8.0751C82.8477 8.59684 83.3317 9 84.8086 9C86.273 9 86.7694 8.59684 86.7694 8.0751C86.7694 7.77866 86.6205 7.57708 86.3351 7.28063C86.1241 7.09091 85.9628 6.77075 85.9628 5.98814V3.01186Z" fill="url(#paint9_linear_149_29_2)"/>
      <path d="M93.5083 8.85771C94.3274 8.85771 94.9231 7.8498 94.9231 6.48617C94.9231 5.62055 94.6749 5.03953 94.2902 5.03953C94.0792 5.03953 93.9054 5.21739 93.5083 5.88142C92.9374 6.98419 92.4658 7.42293 91.7956 7.42293H91.3613C90.6911 7.42293 90.4056 7.03162 90.4056 6.37945V5.76285C90.4056 5.27668 90.629 4.95652 90.9517 4.95652H91.3488C91.6095 4.95652 91.7708 5.1581 91.8825 5.47826C91.9818 5.70356 92.0687 5.86957 92.2796 5.86957C92.6395 5.86957 92.925 5.20553 92.925 4.33992C92.925 3.5336 92.6768 2.97629 92.3169 2.97629C92.0562 2.97629 91.9445 3.17787 91.8328 3.3913C91.7212 3.65217 91.5846 3.80632 91.3488 3.80632H90.9641C90.6166 3.80632 90.4056 3.58103 90.4056 3.02372V2.62055C90.4056 1.95652 90.629 1.57708 91.2371 1.57708H91.6839C92.4782 1.57708 93.0243 2.31225 93.471 3.15415C93.6572 3.50988 93.7689 3.6166 93.9178 3.6166C94.2405 3.6166 94.5135 2.917 94.5135 2.06324C94.5135 1.09091 94.0668 0.142293 92.9746 0.142293H88.5813C87.7746 0.142293 87.303 0.474309 87.303 0.960475C87.303 1.2332 87.4395 1.4585 87.7249 1.75494C87.9235 1.95652 88.0973 2.25296 88.0973 3.10672V5.89328C88.0973 6.74704 87.9111 7.05534 87.7125 7.25692C87.4519 7.51779 87.303 7.79051 87.303 8.06324C87.303 8.52569 87.6877 8.85771 88.482 8.85771H93.5083Z" fill="url(#paint10_linear_149_29_2)"/>
      <path d="M96.9719 9C98.4488 9 98.8955 8.59684 98.8955 8.06324C98.8955 7.73123 98.697 7.51779 98.4612 7.28063C98.2378 7.07905 98.0888 6.71146 98.0888 6.05929V3.16601L100.025 6.80632C100.137 7.00791 100.261 7.09091 100.41 7.09091C100.608 7.09091 100.707 6.98419 100.807 6.80632L102.805 3.14229V6.01186C102.805 6.71146 102.656 7.07905 102.445 7.28063C102.197 7.51779 102.011 7.73123 102.011 8.0751C102.011 8.59684 102.507 9 103.984 9C105.287 9 105.821 8.59684 105.821 8.0751C105.821 7.74308 105.684 7.56522 105.399 7.28063C105.188 7.09091 105.026 6.74704 105.026 6.01186V2.96443C105.026 2.35968 105.15 1.96838 105.399 1.70751C105.684 1.42293 105.845 1.20949 105.845 0.889328C105.845 0.533597 105.56 0.142293 104.604 0.142293H103.823C103.028 0.142293 102.606 0.41502 102.184 1.20949L100.72 4.06719L99.2927 1.20949C98.9328 0.486166 98.4488 0.142293 97.6421 0.142293H96.5375C95.6315 0.142293 95.284 0.498024 95.284 0.913044C95.284 1.22134 95.4206 1.42293 95.7184 1.70751C95.979 1.95652 96.0907 2.35968 96.0907 2.96443V6.05929C96.0907 6.74704 95.9294 7.07905 95.7308 7.28063C95.4454 7.56522 95.2965 7.74308 95.2965 8.06324C95.2965 8.59684 95.6564 9 96.9719 9Z" fill="url(#paint11_linear_149_29_2)"/>
      <path d="M112.585 8.85771C113.404 8.85771 114 7.8498 114 6.48617C114 5.62055 113.752 5.03953 113.367 5.03953C113.156 5.03953 112.982 5.21739 112.585 5.88142C112.014 6.98419 111.543 7.42293 110.873 7.42293H110.438C109.768 7.42293 109.483 7.03162 109.483 6.37945V5.76285C109.483 5.27668 109.706 4.95652 110.029 4.95652H110.426C110.686 4.95652 110.848 5.1581 110.959 5.47826C111.059 5.70356 111.146 5.86957 111.357 5.86957C111.716 5.86957 112.002 5.20553 112.002 4.33992C112.002 3.5336 111.754 2.97629 111.394 2.97629C111.133 2.97629 111.021 3.17787 110.91 3.3913C110.798 3.65217 110.662 3.80632 110.426 3.80632H110.041C109.694 3.80632 109.483 3.58103 109.483 3.02372V2.62055C109.483 1.95652 109.706 1.57708 110.314 1.57708H110.761C111.555 1.57708 112.101 2.31225 112.548 3.15415C112.734 3.50988 112.846 3.6166 112.995 3.6166C113.317 3.6166 113.59 2.917 113.59 2.06324C113.59 1.09091 113.144 0.142293 112.052 0.142293H107.658C106.851 0.142293 106.38 0.474309 106.38 0.960475C106.38 1.2332 106.516 1.4585 106.802 1.75494C107 1.95652 107.174 2.25296 107.174 3.10672V5.89328C107.174 6.74704 106.988 7.05534 106.789 7.25692C106.529 7.51779 106.38 7.79051 106.38 8.06324C106.38 8.52569 106.765 8.85771 107.559 8.85771H112.585Z" fill="url(#paint12_linear_149_29_2)"/>
      <defs>
        <linearGradient id="paint0_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint1_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint2_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint3_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint4_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint5_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint6_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint7_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint8_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint9_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint10_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint11_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
        <linearGradient id="paint12_linear_149_29_2" x1="115.793" y1="4.5" x2="0" y2="4.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE448B"/>
          <stop offset="1" stopColor="#8541DB"/>
        </linearGradient>
      </defs>
    </symbol>
  </SvgUse>
);
