import { h, Fragment } from 'preact';
import styles from './styles.scss';
import { CaretRightIcon } from '@buzzfeed/react-components/dist/module/lib/components/Icons';

export const NotificationPostTitle = ({ title = '', url = '' }) => {
  if (title.length > 65) {
    title = `${title.substr(0, 65).trim()}...`;
  }

  const innerHtml = <span dangerouslySetInnerHTML={{ __html: title }}/>;

  return !url
    ? innerHtml
    : <a href={url} className={styles.postLink}>{innerHtml}</a>;
};

export function IPAPostPublishNotification(props) {
  return (
    <span className={styles.postTitle}>
      You published <NotificationPostTitle {...props} />.
    </span>
  );
}

export function IPAViewThresholdNotification({ threshold, ...props }) {
  return (
    <span className={styles.postTitle}>
      Your post, <NotificationPostTitle {...props} />, got {threshold} views!
    </span>
  );
}

export function IPACommentHeartThresholdNotification({ threshold }) {
  return <span className={styles.postTitle}>Your comment got {threshold} hearts!</span>;
}

export function IPAPostPromotedNotification(props) {
  return (
    <span className={styles.postTitle}>
      Your post, <NotificationPostTitle {...props} />, was promoted.
    </span>
  );
}

export function PostPromoted() {
  return <span className={styles.postTitle}>Congratulations, your post was promoted!</span>;
}

export function CommentReplyNotification({ reply_guy = {}, title, url }) {
  const reply_name = reply_guy.display_name || reply_guy.username || 'Somebody';
  return (
    <Fragment>
      <div className={styles.postTitle}>
        {reply_name}<span className={styles.regular}> replied to your comment on </span>
        <NotificationPostTitle title={title} url={url} />.
      </div>
      <a className={styles.seeReplyButton} href={url}>See Reply
        <CaretRightIcon width='.75rem' height='.75rem' />
      </a>
    </Fragment>
  );
}

export function CommentMentionNotification({ mentioned_by = {}, title, url }) {
  const mentioned_by_name = mentioned_by.display_name || mentioned_by.username || 'Somebody';
  return (
    <Fragment>
      <div className={styles.postTitle}>
        {mentioned_by_name}<span className={styles.regular}> mentioned you on </span>
        <NotificationPostTitle title={title} url={url} />.
      </div>
      <a className={styles.seeReplyButton} href={url}>See Comment
        <CaretRightIcon width='.75rem' height='.75rem' />
      </a>
    </Fragment>
  );
}

export function TrophyAddedNotification({ trophy_name, trophy_img_url, date }) {
  return (
    <div className={styles.trophyAddedContainer}>
      <img
        alt={trophy_name}
        width="75"
        height="75"
        src={`${trophy_img_url}?resize=75:75`}
      />
      <div className={styles.postTitleContainer}>
        <span className={styles.postTitle}>{trophy_name}</span>
        <span className={styles.notificationTimestamp}>{date}</span>
      </div>
    </div>
  );
}

export function TeaserTrophyAddedNotification({ trophy_name, trophy_img_url }) {
  return (
    <span className={styles.trophyAddedContainer}>
      <img
        alt={trophy_name}
        width="75"
        height="75"
        src={`${trophy_img_url}?resize=75:75`}
      />
      <span className={styles.postTitle}>{trophy_name}</span>
      <button className={styles.buttonCTA} type="button">
        Sign in to claim this trophy{' '}
        <span className={styles.buttonCTAChevron}>&rsaquo;</span>
      </button>
    </span>
  );
}

export const NOTIFICATION_COMPONENT_TYPES = {
  comments_new_reply: CommentReplyNotification,
  comments_new_mention: CommentMentionNotification,
  post_promote: PostPromoted,
  ipa_post_promote: IPAPostPromotedNotification,
  ipa_post_publish: IPAPostPublishNotification,
  ipa_view_threshold: IPAViewThresholdNotification,
  ipa_comment_heart_threshold: IPACommentHeartThresholdNotification,
  ipa_trophy_added: TrophyAddedNotification,
  teaser_trophy_added: TeaserTrophyAddedNotification,
};
