import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  id: 'sparkles-icon',
  width: 14,
  height: 14,
  fill: '#E40C78'
};

export const SparklesIcon = () => (
  <symbol {...attributes}>
    <path fill={attributes.fill} d="M13.11 10.023c-1.628 0-2.724-2.3-2.724-3.95 0 1.65-1.096 3.95-2.725 3.95 1.629 0 2.725 2.318 2.725 3.95 0-1.65 1.113-3.95 2.725-3.95zM8.537 6.375C5.994 6.375 4.268 2.66 4.268 0 4.268 2.66 2.54 6.375 0 6.375c2.541 0 4.268 3.715 4.268 6.376 0-2.66 1.726-6.376 4.268-6.376z" />
  </symbol>
);

export const UseSparklesIcon = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
