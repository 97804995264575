import { h } from 'preact';
import { BuzzFeedOriginalsLogo, UseBuzzFeedOriginalsLogo } from './bfo';
import { AsIsLogo, UseAsIsLogo } from './as-is';
import { BuzzFeedNewsLogo, UseBuzzFeedNewsLogo } from './bfn';
import { BuzzFeedLogo, UseBuzzFeedLogo } from './buzzfeed';
import { GoodfulLogo, UseGoodfulLogo } from './goodful';
import { TastyLogo, UseTastyLogo } from './tasty';
import { HuffPostLogo, UseHuffPostLogo } from './huffpost';

export const createDestinationLogoByName = destinationName => ({
  bfo: <BuzzFeedOriginalsLogo />,
  asis: <AsIsLogo />,
  bfn: <BuzzFeedNewsLogo />,
  buzzfeed: <BuzzFeedLogo />,
  goodful: <GoodfulLogo />,
  tasty: <TastyLogo />,
  huffpost: <HuffPostLogo />
}[destinationName]);

export const DestinationLogo = ({ destinationName, ...props }) => {
  switch (destinationName) {
  case 'bfo':
    return <UseBuzzFeedOriginalsLogo {...props} />;
  case 'asis':
    return <UseAsIsLogo {...props} />;
  case 'bfn':
    return <UseBuzzFeedNewsLogo {...props} />;
  case 'buzzfeed':
    return <UseBuzzFeedLogo {...props} />;
  case 'goodful':
    return <UseGoodfulLogo {...props} />;
  case 'tasty':
    return <UseTastyLogo {...props} />;
  case 'huffpost':
    return <UseHuffPostLogo {...props} />;
  }

  return null;
};
