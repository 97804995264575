export const trackNavClick = ({ label, location, ga }) => {
  const tracker = window[ga] || dummyGA;

  tracker('send', {
    hitType: 'event',
    eventCategory: `Nav:${location}`,
    eventAction: 'click',
    eventLabel: label,
  });
};

export const trackBringMeNavClick = ({ url, ga }) => {
  const tracker = window[ga] || dummyGA;

  tracker('send', {
    hitType: 'event',
    eventCategory: 'Article',
    eventAction: 'click:link',
    eventLabel: url,
  });
};

function dummyGA() {
  console.group('Dummy ga global called with');
  var args = Array.prototype.slice.call(arguments);
  console.log(args[0]);
  console.log(JSON.stringify(args[1]));
  console.groupEnd();
}
