/**
 * Convert a URL path into a string identifier value
 * @param {String} url
 */
export const urlToId = url => {
  if (!url) {
    return '';
  }

  let id = url;
  // first see if this is a full URL or a path - we want a path
  try {
    id = new URL(url).pathname;
  } catch (e) {}

  if (id.charAt(0) === '/') {
    id = id.slice(1, id.length);
  }

  return id.replace(/\//g, '_');
};

export function cleanLink(str) {
  return str.replace('http://', '').replace('https://', '').replace('www.', '');
}
