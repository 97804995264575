import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 500,
  height: 500,
  id: 'kando-badge',
  fill: 'none'
};

export const KandoBadge = () => (
  <symbol {...attributes}>
    <g clip-path="url(#clip0_17_2)">
      <path d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z" fill="#FFF000"/>
      <path d="M153 325.7L135 294.5L178.1 269.6L189.5 289.4C192.6 283.8 195.2 278 197.5 272C186.4 264.1 177 254.4 168.7 243.6L117.3 273.3L128.6 293C137.5 308.5 144.9 327.2 144.6 345.4L123.6 341.8C124.3 324.9 118.3 311.6 110 297.2L93.4 268.5L158.9 230.7C156.4 227.1 153.9 223.3 151.6 219.6L168.5 209.8C170.8 213.5 173.2 217.4 175.6 221.1L190.4 212.6C186.4 212.9 182.4 213.1 178.5 213.3L183.1 199.4C192.1 198.4 200.2 198 206.1 198.8L203.9 204.7L213 199.5L221.1 213.5L185 234.2C190.6 241.4 194.9 246.4 201.7 252.5C202.2 244.6 201.5 238 200 230.2L217.8 227.2C220.5 240.1 220.6 251.2 218.3 264.1C222 265.8 226.5 267.7 228.9 266.3C233.2 263.8 229.1 251.4 226.9 245.6L244.3 249.5C247.6 259 253.2 277.4 242.2 283.8C233.8 288.7 222.3 285.3 214.1 281.8C211.4 290.2 208.3 296.5 203.8 304.4L195.9 301L185.9 306.8C193.5 306.6 202.1 306.3 209.7 307.3L207 325.8C198.5 323.6 185.6 323.5 176.9 324.4L179.1 310.7L153 325.7ZM169.7 251.2L176.5 263L130.8 289.4L124 277.6L169.7 251.2ZM163 338.9C167 352.3 168.7 366.7 164.7 380.4L144.1 376.4C148.5 365 148.7 355.5 145.8 343.6L163 338.9ZM154.2 297.1L160.1 307.4L176.8 297.8L170.9 287.5L154.2 297.1ZM183.8 328.7L191.1 341.3C192.8 344.3 194.1 344.3 196.8 342.7L216.9 331.1C221.2 328.6 221.5 327.9 216.4 313.9L234.3 308.6C241.3 327.3 243.3 334.8 233.1 340.7L195.7 362.3C187.2 367.2 183.4 365.8 178.6 357.4L167.5 338.2L183.8 328.7ZM235.7 286.4C247.7 289 263.7 296.1 274.1 302.9L264 321.2C251.1 312 241.6 307.4 226.7 302L235.7 286.4Z" fill="black"/>
      <path d="M288 307.4L278.7 294.1L283.4 291.4C291.8 286.5 299.8 281.4 307.9 276.3L303.6 268.8L277 284.3L270 272.2L296.7 256.8L293.5 251.2L267.7 266.1L240.5 219.1L266.3 204.2L263.4 199.2L234 216.2L226.7 203.6L256.1 186.6L253.2 181.6C246.2 186 239.1 190.3 231.9 194.5L227 197.3L218.3 186.3C240.8 173.3 260.5 161.4 280.4 144.5L292.1 154C284.5 160.9 276.2 166.8 267.7 172.4L271 178.1L300.3 161.2L307.6 173.8L278.3 190.7L281.2 195.7L307.5 180.5L334.7 227.5L308.4 242.7L311.6 248.3L339.3 232.3L346.3 244.4L318.6 260.4L322.4 267C330.3 261.5 338.4 256.5 346.2 250.9C347 232.6 343.7 219.3 336.6 202.6C332.8 193.6 328.7 184.8 323.7 176.2L313.9 181.8L304.4 165.4L314.2 159.8L299.4 134.2L315.7 124.8L330.5 150.4L354.8 136.4C364.7 153.5 374.1 171 383.7 188.4C387.6 195.4 391.3 202.5 394.7 209.7C398.4 217.3 402.8 226.2 403.5 234.6C404.1 242.2 400 246 393.9 249.6L380.6 257.3L368 242L378.4 236C383.7 232.9 383.1 230.7 381.2 225.5C377.4 215 355 174 348.2 162L339.8 166.9C348.3 181.7 355.5 197.6 360.6 213.8C366 230.9 367.6 249.1 364.3 266.8L351.7 265C331 280 309.5 293.7 288 307.4ZM260.6 221.6L265.2 229.6L277 222.8L272.4 214.8L260.6 221.6ZM270.6 238.8L275.4 247.1L287.2 240.3L282.4 232L270.6 238.8ZM287.2 206.3L291.8 214.3L304.1 207.2L299.5 199.2L287.2 206.3ZM297.2 223.5L302 231.8L314.3 224.7L309.5 216.4L297.2 223.5Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_17_2">
        <rect width="500" height="500" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
);

export const UseKandoBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
