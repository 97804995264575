import { h } from 'preact';
import styles from './styles.scss';
import { UseBuzzFeedOriginalsLogo } from '../../shared/svg/logos/bfo';
import { UseBuzzFeedJpLogo } from '../../shared/svg/logos/bfo-jp';
import { Config } from '../../shared/context';
import { Link } from '../../shared/link';
import { withTheme } from '../../shared/withTheme';

const titleIdentifiers = {
  buzzfeed: 'js-bfo-logo-title'
};

export const ThemedLogo = withTheme(({ brand = 'bfo', theme, edition, ...props }) => {
  return (
    <Config.Consumer>{ ({ brand_urls }) => (
      <Link href={brand_urls.default.bfo} className={styles.logoContainer} label={'buzzfeed'} location={'logo'}>
        {edition !== 'jp' && <UseBuzzFeedOriginalsLogo className={`${styles[brand]} ${theme.bfoLogo}`}
          role="img"
          aria-labelledby={titleIdentifiers.buzzfeed} {...props}>
          <title id={titleIdentifiers.buzzfeed}>BuzzFeed Homepage</title>
        </UseBuzzFeedOriginalsLogo>}
        {edition === 'jp' && <UseBuzzFeedJpLogo className={`${styles[brand]} ${theme.bfoLogo}`}
          role="img"
          aria-labelledby={titleIdentifiers.buzzfeed} {...props}>
          <title id={titleIdentifiers.buzzfeed}>BuzzFeed Homepage</title>
        </UseBuzzFeedJpLogo>}
      </Link>
    )}</Config.Consumer>
  );
});
