import {
  internal_link,
  external_link,
  content_action,
  impression as impression_event,
  ab_test
} from '@buzzfeed/client-event-tracking/dist/lib/events';

import {
  createClientEvent
} from '@buzzfeed/client-event-tracking';

const env = (window && window.clientEventTracking && window.clientEventTracking.env)
  ? window.clientEventTracking.env : 'dev';

const sendClientEvent = createClientEvent({ env: env, source: 'web_bf' });

export const externalClick = (...layers) => {
  sendClientEvent(external_link, ...layers);
};

export const internalClick = (...layers) => {
  sendClientEvent(internal_link, ...layers);
};

export const contentAction = (...layers) => {
  sendClientEvent(content_action, ...layers);
};

export const impression = (...layers) => {
  sendClientEvent(impression_event, ...layers);
};

export const abTest = (...layers) => {
  sendClientEvent(ab_test, ...layers);
};
