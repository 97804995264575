import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 500,
  height: 500,
  id: 'omshiro-badge',
  fill: 'none'
};

export const OmshiroBadge = () => (
  <symbol {...attributes}>
    <g clip-path="url(#clip0_13_202)">
      <path d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z" fill="#FFF000"/>
      <path d="M124.9 305.5L106.3 316.2L109.5 321.7C112.4 319.5 115.1 317.5 118.3 315.7C125.6 311.5 135.2 307.8 143.7 309.2C151.9 310.6 158.3 315.3 162.4 322.4C167.9 331.9 167.5 343.5 160.9 352.4C156.6 358.2 150 362.8 143.8 366.4L133.9 354.4C138.5 351.6 144.4 347.5 146.9 342.7C148.7 339.2 147.8 335 145.9 331.7C141.3 323.7 132.9 325.1 126 329.1C123.1 330.8 120 333.1 117.4 335.3L128.4 354.4C134.1 364.3 129.4 372.2 120.3 377.4C114.8 380.6 108.7 382.2 102.4 380.5C97.2 379 92.6 375.2 89.9 370.5C85.7 363.2 85.8 354.3 88.8 346.9C90.9 341.9 93.5 337.8 96.7 333.5L91.7 324.8L72.7 335.8L64.5 321.6L83.5 310.6L78.8 302.4L93.6 293.8L98.3 302L114.5 292.6C113.7 292.4 112.9 292.2 111.9 292.1L119.7 278.1C130.5 279.7 140.7 284.7 149.6 290.8L141.3 306.1C134 301.3 126.4 296.9 118.2 293.8L124.9 305.5ZM103.6 361.3C105.2 364.1 108.2 364.7 110.7 363.2C112.7 362.1 112.5 360.8 111.7 359.4L105 347.9C102.5 351.7 101.1 356.9 103.6 361.3Z" fill="black"/>
      <path d="M206.5 240.3L214.6 254.4L192.1 267.4L197.5 277.9C203.1 274.7 214.1 268.2 218.5 263.9L227.3 277.5C223.1 282.1 211.1 289.1 205.3 292.5L207 295.4C212.6 305.1 215.1 304.9 226.9 298.1C236 292.8 242.6 288.3 236.2 277.3C232.8 271.4 227.8 266.9 222.4 263L234.3 250.9C242.5 256 248.9 261.8 253.7 270.2C258.1 277.7 260.3 286.1 256.6 294.5C252.9 302.9 242.5 309.3 234.7 313.8C228.3 317.5 220.7 321.5 213.1 321.4C202.4 321.3 195.9 313 190.9 304.3L189.4 301.6L175.5 309.6L167.2 295.3L181.5 287.1L175.8 276.8L161.8 284.9L153.7 270.8L168.1 262.5L162.8 253L179.6 243.5L184.5 253L206.5 240.3Z" fill="black"/>
      <path d="M257.7 199.7C262.3 208.9 267.2 217.9 272 226.8C275.5 233.2 278.9 239.5 282.5 245.8C288.1 255.6 293.2 259.8 304 253.5C312.4 248.6 317.1 241.5 316.7 231.6C316.5 225.6 314.3 219.2 312.3 213.5L330.9 212.1C334.6 223.4 336.5 234.5 332.8 246.1C329.4 257 322.1 263.3 312.4 268.9C304.9 273.2 296.7 276.4 288 274.7C277.8 272.6 270.8 262.4 266 253.9C257.4 239.1 249.2 224 240.6 209.1L257.7 199.7Z" fill="black"/>
      <path d="M374.6 132.2L382.1 145.2C378.8 152.4 375.7 159.8 372.8 167.2C375.1 165.3 377.3 163.7 379.8 162.2C386.8 158.2 394.2 156.2 402.2 158.6C408.8 160.6 413.9 165.2 417.3 171.1C422.8 180.7 423.2 191.1 417.6 200.4C411.8 210 402.6 216.8 392.9 222.4C389.1 224.6 385.2 226.6 381.1 228.5L368.4 214.9C374.5 212.6 380.7 209.7 386.3 206.5C393.2 202.5 399.8 198 402.3 189.9C403.2 186.8 402.6 183.4 400.9 180.5C396.4 172.7 387.9 175.7 381.6 179.3C366.6 188 361.3 203.2 356.3 218.7L340.1 211.3C347 194.9 353.3 178.4 358.9 161.5C351 167.3 342.9 172.7 334.5 177.8L325.7 162.5C342.2 152.8 358.6 142.9 374.6 132.2Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_13_202">
        <rect width="500" height="500" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
);

export const UseOmshiroBadge = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
