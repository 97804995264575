import { isThisWeek } from 'date-fns';

export const TOOLTIP_DISMISSED_KEY = 'header_notif_tooltip_dismissed';

export const setTooltipDismissed = () => {
  if (!window || !window.localStorage) {
    return;
  }

  window.localStorage.setItem(TOOLTIP_DISMISSED_KEY, Date.now());
};

export const isTooltipDismissed = () => {
  if (!window || !window.localStorage) {
    return false;
  }

  const tooltipDismissedValue = window.localStorage.getItem(
    TOOLTIP_DISMISSED_KEY
  );
  if (!tooltipDismissedValue || isNaN(tooltipDismissedValue)) {
    return false;
  }

  const dateInMS = parseInt(tooltipDismissedValue, 10);
  const date = new Date(dateInMS);

  return isThisWeek(date, { weekStartsOn: 1 });
};
